@import "@finn-no/troika-css-dev/settings/s-fonts.css";
/*


        Background svg's

        .icon .icon-- {
            background-image: svg-load('functions32/favourite-added.svg');
            background-image: svg-load('functions32/favourite-add-img.svg', currentColor: env(--troika-stone));
        }
*/

.icon {
    &:not(.button--pill),
    &.button--pill::before {
        display: inline-block;
        width: 24px;
        height: 24px;
    }
    &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: inline-block;

        background-repeat: no-repeat;
        background-position: center;
    }

    /* Generated */
    /* 24x */
    &---icon-placeholder::before {
        background-image: svg-load(
            "24x24/-icon-placeholder.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--attachment::before {
        background-image: svg-load(
            "24x24/attachment.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--camera::before {
        background-image: svg-load(
            "24x24/camera.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--check::before {
        background-image: svg-load(
            "24x24/check.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--check-circle::before {
        background-image: svg-load(
            "24x24/check-circle.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--cross::before {
        background-image: svg-load(
            "24x24/cross.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--current-location-android::before {
        background-image: svg-load(
            "24x24/current-location-android.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--current-location-ios-filled::before {
        background-image: svg-load(
            "24x24/current-location-ios-filled.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--current-location-ios-outline::before {
        background-image: svg-load(
            "24x24/current-location-ios-outline.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--delete::before {
        background-image: svg-load(
            "24x24/delete.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--download::before {
        background-image: svg-load(
            "24x24/download.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--eye-hidden::before {
        background-image: svg-load(
            "24x24/eye-hidden.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--eye-open::before {
        background-image: svg-load(
            "24x24/eye-open.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--facebook::before {
        background-image: svg-load(
            "24x24/facebook.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--heart-active::before {
        background-image: svg-load(
            "24x24/heart-active.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--heart-neutral::before {
        background-image: svg-load(
            "24x24/heart-neutral.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--heart-on-image-active::before {
        background-image: svg-load("24x24/heart-on-image-active.svg");
    }
    &--heart-on-image-neutral::before {
        background-image: svg-load("24x24/heart-on-image-neutral.svg");
    }
    &--history::before {
        background-image: svg-load(
            "24x24/history.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--house::before {
        background-image: svg-load(
            "24x24/house.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--images::before {
        background-image: svg-load(
            "24x24/images.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--instagram::before {
        background-image: svg-load(
            "24x24/instagram.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--lightbulb::before {
        background-image: svg-load(
            "24x24/lightbulb.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--link::before {
        background-image: svg-load(
            "24x24/link.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--mail::before {
        background-image: svg-load(
            "24x24/mail.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--notepad::before {
        background-image: svg-load(
            "24x24/notepad.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--overflow-menu-horizontal::before {
        background-image: svg-load(
            "24x24/overflow-menu-horizontal.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--overflow-menu-vertical::before {
        background-image: svg-load(
            "24x24/overflow-menu-vertical.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--pencil::before {
        background-image: svg-load(
            "24x24/pencil.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--pin::before {
        background-image: svg-load(
            "24x24/pin.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--push-notification::before {
        background-image: svg-load(
            "24x24/push-notification.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--question-circle::before {
        background-image: svg-load(
            "24x24/question-circle.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--rocket::before {
        background-image: svg-load(
            "24x24/rocket.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--search::before {
        background-image: svg-load(
            "24x24/search.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--send::before {
        background-image: svg-load(
            "24x24/send.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--share-android::before {
        background-image: svg-load(
            "24x24/share-android.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--share-ios::before {
        background-image: svg-load(
            "24x24/share-ios.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--star-filled::before {
        background-image: svg-load(
            "24x24/star-filled.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--star-outline::before {
        background-image: svg-load(
            "24x24/star-outline.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--twitter::before {
        background-image: svg-load(
            "24x24/twitter.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--upload::before {
        background-image: svg-load(
            "24x24/upload.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--view-card::before {
        background-image: svg-load(
            "24x24/view-card.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--view-grid::before {
        background-image: svg-load(
            "24x24/view-grid.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--view-list::before {
        background-image: svg-load(
            "24x24/view-list.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--warning-circle::before {
        background-image: svg-load(
            "24x24/warning-circle.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--warning-triangle::before {
        background-image: svg-load(
            "24x24/warning-triangle.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--youtube::before {
        background-image: svg-load(
            "24x24/youtube.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--alphabetical-sorting::before {
        background-image: svg-load(
            "24x24/alphabetical-sorting.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--bold::before {
        background-image: svg-load(
            "24x24/bold.svg",
            currentColor: env(--troika-stone)
        );
    }

    /* Motor */
    &--automatic::before {
        background-image: svg-load(
            "motor/automatic.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--calendar-b::before {
        background-image: svg-load(
            "motor/calendar-b.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--car-star::before {
        background-image: svg-load(
            "motor/car-star.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--document-b::before {
        background-image: svg-load(
            "motor/document-b.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--electric::before {
        background-image: svg-load(
            "motor/electric.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--fuel::before {
        background-image: svg-load(
            "motor/fuel.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--manual::before {
        background-image: svg-load(
            "motor/manual.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--milage::before {
        background-image: svg-load(
            "motor/milage.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--nbf::before {
        background-image: svg-load(
            "motor/nbf.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--return::before {
        background-image: svg-load(
            "motor/return.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--service::before {
        background-image: svg-load(
            "motor/service.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--warranty::before {
        background-image: svg-load(
            "motor/warranty.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--gas::before {
        background-image: svg-load(
            "motor/gas.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--mc-member::before {
        background-image: svg-load(
            "motor/mc-member.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--el-bensin::before {
        background-image: svg-load(
            "motor/el-bensin.svg",
            currentColor: env(--troika-stone)
        );
    }

    &--apartment::before {
        background-image: svg-load(
            "32x32/apartment.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--bed::before {
        background-image: svg-load(
            "32x32/bed.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--energy-level::before {
        background-image: svg-load(
            "32x32/energy-level.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--floors::before {
        background-image: svg-load(
            "32x32/floors.svg",
            currentColor: env(--troika-stone)
        );
    }
    &--square-foot::before {
        background-image: svg-load(
            "32x32/square-foot.svg",
            currentColor: env(--troika-stone)
        );
    }

    /* 16x */
    &--calendar-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/calendar-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--check-circle-filled-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/check-circle-filled-mini.svg");
        }
    }
    &--check-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/check-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--chevron-left {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/chevron-left.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--chevron-down {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--chevron-right {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/chevron-right.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--chevron-up {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/chevron-up.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--cog-wheel-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/cog-wheel-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--cross-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/cross-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--eye-hidden-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/eye-hidden-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--eye-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/eye-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--forbidden-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/forbidden-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--heart-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/heart-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--info-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/info-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--launch {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/launch.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--location-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/location-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--minus-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/minus-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--pagination-first {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/pagination-first.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--plus-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/plus-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--question-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/question-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--search-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load(
                "16x16/search-mini.svg",
                currentColor: env(--troika-stone)
            );
        }
    }
    &--star-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/star-mini.svg");
        }
    }
    &--verified {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/verified.svg");
        }
    }
    &--warning-triangle-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/warning-triangle-mini.svg");
        }
    }
    &--arrow-up {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/arrow-up.svg");
        }
    }
    &--arrow-down {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/arrow-down.svg");
        }
    }
    &--lightbulb-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/lightbulb-mini.svg");
        }
    }
    &--tag-mini {
        &:not(.button--pill),
        &.button--pill::before {
            width: 16px;
            height: 16px;
        }
        &::before {
            background-image: svg-load("16x16/tag-mini.svg");
        }
    }

    /* Global 32 (Top menu) */
    &--loggedin::before {
        background-image: svg-load("global32/loggedin.svg");
    }
    &--login::before {
        background-image: svg-load("global32/login.svg");
    }
    &--messages::before {
        background-image: svg-load("global32/messages.svg");
    }
    &--newad::before {
        background-image: svg-load("global32/newad.svg");
    }
    &--notifications::before {
        background-image: svg-load("global32/notifications.svg");
    }
    &--web-notification-bell::before {
        background-image: svg-load("global32/web-notification-bell.svg");
    }
    &--yourads::before {
        background-image: svg-load("global32/yourads.svg");
    }

    /* Map */
    &--draw-area::before {
        background-image: svg-load("map/draw-area.svg");
    }
    &--my-position::before {
        background-image: svg-load("map/my-position.svg");
    }

    /* Markets */
    &--boat::before {
        background-image: svg-load(
            "markets45x35/boat.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--car::before {
        background-image: svg-load(
            "markets45x35/car.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--classifieds::before {
        background-image: svg-load(
            "markets45x35/classifieds.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--economy::before {
        background-image: svg-load(
            "markets45x35/economy.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--jobs::before {
        background-image: svg-load(
            "markets45x35/jobs.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--kart::before {
        background-image: svg-load(
            "markets45x35/kart.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--kunnytt::before {
        background-image: svg-load("markets45x35/kunnytt.svg");
        width: 45px;
        height: 35px;
    }
    &--mc::before {
        background-image: svg-load(
            "markets45x35/mc.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--mittanbud::before {
        background-image: svg-load(
            "markets45x35/mittanbud.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--moteplassen::before {
        background-image: svg-load(
            "markets45x35/moteplassen.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--realestate::before {
        background-image: svg-load(
            "markets45x35/realestate.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--shopping::before {
        background-image: svg-load(
            "markets45x35/shopping.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--smalljobs::before {
        background-image: svg-load(
            "markets45x35/smalljobs.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--travel::before {
        background-image: svg-load(
            "markets45x35/travel.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
    &--vehicles::before {
        background-image: svg-load(
            "markets45x35/vehicles.svg",
            currentColor: env(--troika-stone)
        );
        width: 45px;
        height: 35px;
    }
}

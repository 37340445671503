@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-focus.css";
/*

    Fancy Check and Radio

    Markup examples

    <div class="input-toggle">
        <input type="checkbox" id="fancycheckbox1">
        <label for="fancycheckbox1">Nothing</label>
    </div>

*/
.input-toggle {
    padding: 1px 0;
    position: relative;

    input {
        padding: 0;
        margin: 0;
        position: absolute;
        left: 0;
        opacity: 0;
        height: 22px;
        width: 22px;
    }
    label {
        cursor: pointer;
        color: var(--troika-licorice);
        display: block;
        padding: 2px 0 2px 28px;
        line-height: $base-lineheight--normal;
        font-size: $base-size--normal;
        font-weight: normal;
        user-select: none;
        position: relative;
        -webkit-tap-highlight-color: transparent;
    }

    input + label:before {
        content: "";
        position: absolute;
        display: block;
        vertical-align: top;
        transition: all 0.2s ease-out;
    }
    input + label:before {
        left: 0px;
        top: 2px;
        width: 20px;
        height: 20px;
        background-color: var(--troika-milk);
    }
    input:focus + label {
        @include focus-outline;
    }
    input:focus:not(:focus-visible) + label {
        outline: none;
    }
    input:focus-visible + label {
        @include focus-outline;
    }
    input[type="checkbox"] {
        & + label:before {
            background-image: svg-load("forms/checkmark.svg");
            background-repeat: no-repeat;
            border-radius: 2px;
            border: 1px solid var(--troika-stone);
        }
        &:hover + label:before {
            border-color: var(
                --troika-primary-blue,
                env(--troika-primary-blue)
            );
            background-color: var(--troika-ice);
        }
        &:checked {
            & + label:before {
                border-color: var(
                    --troika-primary-blue,
                    env(--troika-primary-blue)
                );
                background-color: var(
                    --troika-primary-blue,
                    env(--troika-primary-blue)
                );
            }
            &:hover + label:before {
                border-color: #004fc9;
                background-color: #004fc9;
            }
        }
    }
    input[type="radio"] {
        & + label:before {
            border: 1px solid var(--troika-stone);
            border-radius: 50%;
        }
        &:hover + label:before {
            border-color: var(
                --troika-primary-blue,
                env(--troika-primary-blue)
            );
            background-color: var(--troika-ice);
        }
        &:checked {
            & + label:before {
                border-color: var(
                    --troika-primary-blue,
                    env(--troika-primary-blue)
                );
                border-width: 6px;
            }
            &:hover + label:before {
                border-color: #004fc9;
                border-width: 6px;
            }
        }
    }

    &--is-disabled {
        input,
        label {
            pointer-events: none;
        }
        & label {
            color: env(--troika-sardine) !important;
        }
        & label:before {
            border-color: env(--troika-sardine) !important;
        }
        & input:checked + label:before {
            border-color: env(--troika-sardine) !important;
        }
        & input[type="checkbox"]:checked + label:before {
            background-color: env(--troika-sardine) !important;
        }
    }
}

@import "@finn-no/troika-css-dev/tools/t-link.css";
/*


        Link styling & variants


*/
.link {
    @include link;

    &:not(&--block):focus,
    &:not(&--block) a:focus {
        outline: none;
    }

    &--show-history,
    &--show-history a {
        @include link--history;
    }

    &--block,
    &--block a {
        @include link--block;
    }

    &--dark,
    &--dark a {
        @include link--dark;
    }
}

@mixin displayTable {
    display: table;
    width: 100%;
    table-layout: fixed;
}

@mixin displayCell {
    display: table-cell;
    vertical-align: middle;
}

@mixin screenReaderOnly {
    /* h5bg */
    /* https://github.com/h5bp/html5-boilerplate/blob/dbc3ed973573a77122f6b8a2aebd0a76a44ad6a6/src/css/main.css#L153 */
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

@mixin printOnly {
    display: none;

    @media print {
        display: block !important;
    }
}

@mixin noPrint {
    @media print {
        display: none !important;
    }
}

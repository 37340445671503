.list-descriptive {
    & div {
        break-inside: avoid;
        margin-bottom: 16px;
    }
    & dt {
        font-weight: normal;
        margin: 0;
    }
    & dd {
        font-weight: bold;
        margin: 0;
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-text.css";

/*

    #ad-stream

*/

.ad-stream {
    margin: 0 -8px;
    @media (--min768) {
        margin: 0 -16px;
    }
    &__unit {
        padding: 0 8px 32px;
        @media (--min768) {
            padding: 0 16px 32px;
        }
    }
    &__icon {
        display: block;
        width: 30px;
        height: 30px;
    }
    &__price {
        font-size: $base-size--normal;
        line-height: $base-lineheight--normal;
        font-weight: bold;
    }
    &__location {
        color: env(--troika-stone);
        font-size: $d1-size--normal;
        line-height: $d1-lineheight--normal;
        margin-top: 4px;
        margin-bottom: 0;
        @include truncate;
    }
    &__description {
        font-size: $d1-size--normal;
        line-height: $d1-lineheight--normal;
        margin-top: 4px;
        margin-bottom: 0;
        @include truncate;
        @media (--min768) {
            font-size: $base-size--normal;
            line-height: $base-lineheight--normal;
            margin-top: 0;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";

@mixin input__label() {
    display: block;
    color: env(--troika-licorice);
    font-weight: bold;
    position: relative;
    font-size: $caption-size--normal;
    line-height: $caption-lineheight--normal;
    padding-bottom: 4px;
}

@mixin input__field() {
    display: block;
    color: env(--troika-licorice);
    caret-color: env(--troika-primary-blue);
    background-color: env(--troika-milk);
    border: 1px solid env(--troika-sardine);
    border-radius: 4px;
    outline: none;
    width: 100%;
    /* ie11 fix for correct height */
    min-height: 42px;
    @media (--min480) {
        min-height: 45px;
    }
    padding: $input-padding-v $input-padding-h;
    &::placeholder {
        color: #a1afc4;
    }
}

@mixin input__field--hover() {
    border-color: env(--troika-stone);
}

@mixin input__field--focus() {
    border-color: env(--troika-primary-blue);
    box-shadow: inset 0 0 0 1px env(--troika-primary-blue);
}

@mixin input__field--disabled() {
    color: env(--troika-sardine);
    background-color: #ececec !important;
    border-color: #ececec !important;
    pointer-events: none;
}

@mixin input--invalid() {
    border-color: env(--troika-cherry);
    caret-color: env(--troika-cherry);
}

@mixin input--invalid--focus() {
    border-color: env(--troika-cherry);
    box-shadow: inset 0 0 0 1px env(--troika-cherry);
}

@mixin input--is-read-only() {
    background-color: transparent !important;
    border-color: transparent !important;
    padding-left: 0 !important;
    padding-bottom: $input-padding-v !important;
    pointer-events: none;
}

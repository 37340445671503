@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-button.css";
@import "@finn-no/troika-css-dev/tools/t-pageholder.css";
/*

    This is a very bad example, and not using BEM for now.
    Its done like this to support both spaden and Troika
    styling on the same markup. (The markup is injected via
    common browser bundle js and is common for all pages
    either they run Troika or Spaden)

    This should be rewritten when Spaden is
    out of the picture!

*/
.toast {
    transition-property: max-height;
    transition-timing-function: linear;
    overflow-y: hidden;

    &--success .inner,
    &--error .inner {
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: 16px;
        padding: 16px 16px 16px 54px;
        background-color: transparent;
    }
    &--error {
        background-color: env(--troika-salmon);
    }

    &--success {
        background-color: env(--troika-mint);
    }

    &--success .toast--action__actionbutton button {
        border: 0;
        @include buttonConstruct(
            env(--troika-primary-blue),
            env(--troika-mint),
            env(--troika-mint),
            env(--troika-mint)
        );

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
    &--error .toast--action__actionbutton button {
        border: 0;
        @include buttonConstruct(
            env(--troika-primary-blue),
            env(--troika-salmon),
            env(--troika-salmon),
            env(--troika-salmon)
        );

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline;
        }
    }
    &--error .inner {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cpath fill='%23FF5844' d='M11 22c6 0 11-5 11-11S17 0 11 0 0 5 0 11s5 11 11 11z'/%3E %3Cpath fill='%23FFF' d='M17 10H5v2h12'/%3E %3C/g%3E %3C/svg%3E");
    }
    &--success .inner {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 22'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cpath fill='%2328CD94' d='M11 22c6 0 11-5 11-11S17 0 11 0 0 5 0 11s5 11 11 11z'/%3E %3Cpath fill='%23FFF' d='M6.4 9.6L5 11l4.5 4.5 7-7L15 7l-5.5 5.6'/%3E %3C/g%3E %3C/svg%3E");
    }
    & .container .page {
        @include pageholderNoPadding;
        background-color: transparent;
    }
    &--action {
        &__wrapper {
            position: relative;
        }
        &__wrapper:before,
        &__wrapper:after {
            content: " ";
            display: table;
        }
        &__wrapper:after {
            clear: both;
        }
        &__message {
            @media (--max768) {
                width: 75%;
            }
        }
        &__actionbutton {
            @media (--max768) {
                width: 25%;
                right: 0;
                position: absolute;
                text-align: right;
            }

            & button {
                border: 0;
                @include buttonConstruct(
                    env(--troika-primary-blue),
                    env(--troika-milk),
                    env(--troika-milk),
                    env(--troika-milk)
                );

                &:hover,
                &:focus,
                &:active {
                    text-decoration: underline;
                }
            }
        }
    }
}

/*
    #ad-gallery
*/

.ad-gallery {
    padding: 8px;
    display: -ms-flexbox;
    -ms-flex-wrap: wrap;
    -ms-flex-direction: column;
    -webkit-flex-flow: row wrap;
    flex-flow: row wrap;
    display: -webkit-box;
    display: flex;
    align-items: stretch;
    &__unit {
        flex: auto;
        flex-grow: 1;
        margin: 8px;
        min-width: 200px; /* items will not go below this */
        width: 33%; /* items will be minimum this and stretch to fill the empty space */
        & img {
            width: 100%;
            height: auto;
        }

        &:nth-child(4n + 0) {
            /* use to give a certain pattern of items a different with compared to others */
            width: 100%;
        }
    }
}

.image-block {
    width: 100%;
    border: 1px solid #c3ccd9;
    border-radius: 8px;
    &--canvas {
        background-color: #00f;
    }
    &--contain /* Contain needs a size on the wrapper it is supposed to contain within */ {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            object-fit: contain;
            object-position: 50% 50%;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            width: auto;
        }
    }

    &--cover /* Cover needs height and width on the img tag */ {
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            height: 95px;
            width: 100%;
            object-fit: cover;
            object-position: 50% 50%;
            max-width: 100%;
            max-height: 100%;
        }
    }
    &--scale {
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            max-width: 100%;
            max-height: 100%;
            height: auto;
        }
    }
}

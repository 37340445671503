@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-link.css";

.ads {
    margin: 8px;
    &__unit {
        background: var(--troika-milk);
        position: relative; /* Creates a context for the links clickarea */
        padding: 8px;
        z-index: 1;
        border-radius: 12px;
        border: 2px solid transparent;
        &:not(.ads__unit--banner):focus-within,
        &:not(.ads__unit--banner):hover {
            background-color: var(--troika-ice);
        }
        &:not(.ads__unit--banner):focus-within,
        &:not(.ads__unit--banner):focus {
            text-decoration: none;
            border: 2px solid env(--troika-focused);
        }
        &--banner {
            width: 100%;
            text-align: center;
            align-self: center;
        }
        &--banner:empty {
            height: 288px;
            @media (--min768) {
                height: 426px;
            }
        }
        &__link {
            @include link;
            @include link--dark;
            @include link--block;
            &::after /* Stretches the main link to cover the whole unit */ {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
            &:focus {
                outline: none;
            }
        }
        &__tools {
            position: absolute;
            top: 14px;
            right: 14px;
            z-index: 2;
            & button + button {
                margin-left: 8px;
            }
        }
        &__badge {
            display: inline-block;
            color: env(--troika-licorice);
            background: env(--troika-toothpaste);
            border-radius: 4px;
            line-height: 16px;
            font-size: 12px;
            padding: 4px 8px;
            margin-bottom: 8px;
            order: -1;
            align-self: flex-start;
            top: 25px;
            left: 25px;
            position: absolute;
        }

        &__logo {
            display: flex;
            img {
                max-height: 40px;
                max-width: 75px;
            }
        }
        &__img {
            & img {
                max-width: 100%;
                display: block;
            }
            /* @TODO ratio is deprecated is the old naming and design, and will eventually be removed */
            &__ratio {
                position: relative;
                border: 1px solid #c3ccd9;
                background-color: env(--troika-ice);
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: 8px;
                &__price {
                    background: rgba(71, 68, 69, 0.8);
                    color: env(--troika-milk);
                    padding: 4px 8px;
                    font-size: $base-size--normal;
                    line-height: $base-lineheight--normal;
                    display: inline-block;
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    border-radius: 0 8px 0 0;
                    z-index: 1;
                }
            }
            &__frame {
                position: relative;
                border: 1px solid #c3ccd9;
                background-color: env(--troika-ice);
                border-radius: 8px;
                overflow: hidden;
                margin-bottom: 8px;
                &__overlay {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    color: env(--troika-milk);
                    font-weight: bold;
                    padding: 38px 16px 8px 16px;
                    background: linear-gradient(
                        rgba(0, 0, 0, 0),
                        cubic-bezier(0.6, 0.2, 0.55, 1),
                        rgba(0, 0, 0, 0.8)
                    );
                    width: 100%;
                }
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            word-break: break-word;
            &__title {
                font-size: $base-size--normal;
                line-height: $base-lineheight--normal;
                &--fav-placeholder::before {
                    content: " ";
                    color: transparent;
                    float: right;
                    height: 21px;
                    width: 38px;
                }
                &--line-clamp2 {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @supports (-webkit-line-clamp: 2) {
                        white-space: normal;
                        hyphens: auto;
                        word-break: break-word;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }
            }
            &__details {
                order: -1; /* Move the details above the main title without ruining the semantics */
                font-size: $d1-size--normal;
                line-height: $d1-lineheight--normal;
                color: var(--troika-stone);
                display: flex;
                align-items: center;
                & span,
                & div {
                    display: inline-block;
                }
                & span + span,
                & div + div {
                    margin-left: 16px;
                }
            }
            &__status {
                order: -1;
            }
            &__keys {
                display: flex;
                font-weight: bold;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0 0 8px 0; /* Minimum spacing trick for correct alignment both on single line and when it wraps */
                & span {
                    margin: 0 8px;
                }
            }

            &__list {
                font-size: $d1-size--normal;
                line-height: $d1-lineheight--normal;
                & span + span::before {
                    display: inline-block;
                    content: "";
                    height: 8px;
                    width: 8px;
                    border-radius: 4px;
                    background-color: var(--troika-stone);
                    margin-left: 8px;
                    margin-right: 8px;
                }
            }
            &__distance {
                display: inline-flex !important;
                & span::before {
                    width: 22px;
                    height: 22px;
                }
                & span {
                    margin: auto;
                }
            }
        }
    }
}
/**
 * Main display concept : Keep clean of other styling;
 */

.ads {
    &--list &__unit {
        display: flex;
        flex-direction: row;
        /*flex-wrap: wrap; */
        &__tools {
            top: 8px;
            right: 8px;
        }
        &__img {
            flex: 1 1 auto;
            width: 30%;
            margin-right: 16px;
        }
        &__content {
            flex: 1 1 auto;
            width: 70%;
        }
        &--fullwidth {
            width: 100%;
        }
        &--banner {
            justify-content: center;
        }
    }
    &--grid,
    &--liquid {
        /* Flex fallback, progressive enhancemenet to display:grid below */
        display: flex;
        flex-flow: wrap;
        position: relative;
    }
    &--grid &__unit,
    &--liquid &__unit {
        flex: 0 1 auto;
        width: 100%;
        &--fullwidth {
            width: 100%;
            flex: 1 1 100%;
        }
        &__content {
            &__details {
                justify-content: space-between;
            }
        }
    }

    &--liquid--cols1to2 &__unit {
        @media (--min480) {
            width: 50%;
        }
    }
    &--liquid--cols1to3 &__unit {
        @media (--min480) {
            width: 50%;
        }
        @media (--min900) {
            width: 33.3334%;
        }
    }
    &--liquid--cols2to3 &__unit {
        width: 50%;
        &__badge {
            position: static;
            @media (--min480) {
                position: absolute;
            }
        }
        @media (--min900) {
            width: 33.3334%;
        }
    }
}

@supports (display: grid) {
    .ads--grid {
        /* Display grid */
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-column-gap: 1px;
        grid-row-gap: 0;
        & .ads__unit--fullwidth {
            grid-column-start: 1;
            grid-column-end: -1;
        }
    }
}
/**
 * Ads  --big-ad experiment 36, should be written straight into
 * the ads code when we know what we want with this
 */
.ads {
    &__unit {
        position: relative;
        &--big-ad {
            width: 66.66662% !important;
            display: flex;
            flex-direction: column;
        }
        &--big-ad &__img {
            flex: 0 0 auto;
        }
        &--big-ad &__content {
            flex: 1 1 100%;
        }
        &--big-ad &__img__ratio {
            padding-top: 50%;
            margin-bottom: 0;
            border-radius: 8px 8px 0 0;
        }
        &--big-ad .img-format__img {
            width: 100%;
            height: auto;
            max-height: none;
        }
        &--big-ad img {
            max-width: 100%;
            display: block;
        }
        &--big-ad &__content {
            border: 1px solid #ddd;
            border-top: 0;
            border-radius: 0 0 8px 8px;
            padding: 8px 8px 0 8px;
            &__title {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}
/* override listview fix */
.ads--list {
    & .ads__unit--big-ad.ads__unit {
        flex-direction: column;
    }
    & .ads__unit--big-ad {
        .ads__unit__img {
            width: 100%;
            margin-right: 0;
        }
        .ads__unit__content {
            width: 100%;
        }
    }
}

@media (max-width: 899px) {
    .ads {
        &__unit {
            &--big-ad {
                width: 100% !important;
            }
        }
    }
}

/**
 * Ad takeover stuff, should probably be moved somewhere else
 */
.has-ad-takeover /* set on body if ad-takeover = true */ {
    & .pageholder {
        background-color: transparent;
    }
    & .ads {
        margin: 0;
        width: 100vw;
        &__unit {
            padding: 16px;
            border-radius: 0;
            z-index: 2;
            &--banner,
            &--native {
                z-index: 1;
                border: 0;
                &::before {
                    content: "";
                    background-color: var(--troika-milk);
                    position: absolute;
                    display: block;
                    height: 150vh;
                    top: -150vh;
                    width: 100vw;
                    right: 0;
                    z-index: 1;
                }
            }
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            background: var(--troika-milk);
            width: 100vw;
            display: block;
            z-index: 1;
            height: 150vh;
        }
    }
}

/**
 * ".ad-takeover" is a classname used in the Schibsted wide solution
 * from the inventory team https://github.schibsted.io/inventory/ad-takeover
 * the --deprecated version can be removed when all mfinn verticals are moved
 * to https://github.schibsted.io/finn/search-result-pages that use this solution
 */
.ad-takeover-deprecated {
    z-index: 0 !important;
    height: 100vh;
    overflow: visible;
    & iframe {
        position: fixed;
        overflow: hidden;
        top: 50px;
        left: 0;
        width: 100vw;
        height: calc(100vh - 50px);
    }
}
/*
 * netboard style for small tablets
 */
@media (--min768) and (--max900) {
    .ads {
        &__unit {
            &--banner {
                transform-origin: top left;
                transform: scale(0.82);
                max-height: 350px;
            }
        }
        &--list {
            .ads {
                &__unit {
                    &--banner {
                        justify-content: left;
                    }
                }
            }
        }
    }
}

/*
 * Variant optimized for small cards, used by Reise
 */
.ads--compact-cards {
    .ads__unit__img__frame {
        margin-bottom: 0;
        border-width: 0;
    }
    .ads__unit__img__frame__overlay {
        background: linear-gradient(
            rgba(0, 0, 0, 0),
            cubic-bezier(0.6, 0.3, 0.55, 1),
            rgba(0, 0, 0, 0.8)
        );
        padding-top: env(--troika-spacing-700);
    }
    .ads__unit__content__title {
        font-size: $t4-size--normal;
        line-height: $t4-lineheight--normal;
        margin-bottom: 0;
    }
    &.ads--liquid--cols1to2 {
        @media (--min900) {
            .ads__unit__content__title {
                font-size: $t3-size--normal;
                line-height: $t3-lineheight--normal;
            }
        }
    }
}

@import "@finn-no/troika-css-dev/tools/t-clearfix.css";

.grid {
    &:not(&--auto-height) {
        @include clear-fix;
    }

    &__unit {
        float: left;
    }

    &--auto-height {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;

        & .grid__unit {
            flex: 0 1 auto;
        }
    }
    &--vertical-center {
        & .grid__unit {
            align-self: center;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.context-box {
    position: relative;
    padding: 8px 16px;
    background-color: var(--troika-mint);
    border: 2px solid var(--troika-pea);
    border-radius: 4px;

    &:before {
        content: "";
        position: absolute;
        display: none;
        width: 16px;
        height: 16px;
        background-color: inherit;
        transform: rotate(45deg);
        border: 2px solid var(--troika-pea);
    }
    &--up,
    &--up-right,
    &--up-left {
        margin-top: 16px;

        &:before {
            display: block;
            top: -11px;
            left: 50%;
            margin: 2px -8px;
            border-width: 2px 0 0 2px;
        }
    }
    &--up-right {
        &:before {
            right: 20px;
            left: auto;
        }
    }
    &--up-left {
        &:before {
            left: 20px;
        }
    }
    &--down {
        margin-bottom: 16px;

        &:before {
            display: block;
            bottom: -11px;
            left: 50%;
            margin: 2px -8px;
            border-width: 0 2px 2px 0;
        }
    }

    &--left {
        margin-left: 16px;

        &:before {
            display: block;
            top: 50%;
            left: -1px;
            margin: -8px -8px;
            border-width: 0 0 2px 2px;
        }
    }
    &--right {
        margin-right: 16px;

        &:before {
            display: block;
            top: 50%;
            right: -1px;
            margin: -8px -8px;
            border-width: 2px 2px 0 0;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";
@import "@finn-no/troika-css-dev/tools/t-text.css";
@import "@finn-no/troika-css-dev/tools/t-link.css";

/*

    #ad-preview

*/

.ad-preview {
    position: relative;

    &__click-area {
        display: block;
        @include link;
        @include link--block;
        @include link--dark;
    }
    /* __actions is deprecated */
    &__actions,
    &__actions-left {
        position: absolute;
        top: 0;
        left: 0;
        padding: 8px;
    }
    &__actions-right {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
    }
    /* __actions is deprecated */
    &--actions-left &__actions {
        left: 0;
    }
    /* __actions is deprecated */
    &--actions-right &__actions {
        right: 0;
    }
    &__media {
        position: relative;
        border: 1px solid env(--troika-sardine);
        border-radius: $global-radius;
        overflow: hidden;

        &__tag {
            position: absolute;
            bottom: 0;
            left: 0;
            padding-right: 0px;
            max-width: 100%;
            width: 100%;
            display: flex;
        }
    }
    &__tag {
        background-color: color-mod(env(--troika-licorice) alpha(80%));
        border-radius: 0 $global-radius 0 0;
        padding: 4px 8px;
        color: env(--troika-milk);
        @include truncate;
    }
    &__logo {
        flex: 1;
        display: flex;
        img {
            max-height: 38px;
            max-width: 100%;
            align-self: flex-end;
            margin-left: auto;
            border-radius: 8px 0 0 0;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-spacing.css";

/*


		# Colsnfrom

		Highly customizable grid-sizing system

		- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		.cols{n}upto{breakpoint}, and .cols{n}from990
		these 2 work and should in most cases be used together.

		- - - - - - - - - - - - - - - - - - - - - - - - - - - - -

		cols{n}upto{breakpoint}
		cols{n}from990

		{n}
		1, 2, 3, 4, 5, 6, 7, 8

		{breakpoint}
		480, 640, 768, 990


*/

@media (--max990) {
    .grid--cols1upto990 > .grid__unit {
        width: $s1of1;
    }
    .grid--cols2upto990 > .grid__unit {
        width: $s1of2;
    }
    .grid--cols3upto990 > .grid__unit {
        width: $s1of3;
    }
    .grid--cols4upto990 > .grid__unit {
        width: $s1of4;
    }
    .grid--cols5upto990 > .grid__unit {
        width: $s1of5;
    }
    .grid--cols6upto990 > .grid__unit {
        width: $s1of6;
    }
    .grid--cols7upto990 > .grid__unit {
        width: $s1of7;
    }
    .grid--cols8upto990 > .grid__unit {
        width: $s1of8;
    }
}
@media (--max768) {
    .grid--cols1upto768 > .grid__unit {
        width: $s1of1;
    }
    .grid--cols2upto768 > .grid__unit {
        width: $s1of2;
    }
    .grid--cols3upto768 > .grid__unit {
        width: $s1of3;
    }
    .grid--cols4upto768 > .grid__unit {
        width: $s1of4;
    }
    .grid--cols5upto768 > .grid__unit {
        width: $s1of5;
    }
    .grid--cols6upto768 > .grid__unit {
        width: $s1of6;
    }
    .grid--cols7upto768 > .grid__unit {
        width: $s1of7;
    }
    .grid--cols8upto768 > .grid__unit {
        width: $s1of8;
    }
}
@media (--max640) {
    .grid--cols1upto640 > .grid__unit {
        width: $s1of1;
    }
    .grid--cols2upto640 > .grid__unit {
        width: $s1of2;
    }
    .grid--cols3upto640 > .grid__unit {
        width: $s1of3;
    }
    .grid--cols4upto640 > .grid__unit {
        width: $s1of4;
    }
    .grid--cols5upto640 > .grid__unit {
        width: $s1of5;
    }
    .grid--cols6upto640 > .grid__unit {
        width: $s1of6;
    }
    .grid--cols7upto640 > .grid__unit {
        width: $s1of7;
    }
    .grid--cols8upto640 > .grid__unit {
        width: $s1of8;
    }
}
@media (--max480) {
    .grid--cols1upto480 > .grid__unit {
        width: $s1of1;
    }
    .grid--cols2upto480 > .grid__unit {
        width: $s1of2;
    }
    .grid--cols3upto480 > .grid__unit {
        width: $s1of3;
    }
    .grid--cols4upto480 > .grid__unit {
        width: $s1of4;
    }
    .grid--cols5upto480 > .grid__unit {
        width: $s1of5;
    }
    .grid--cols6upto480 > .grid__unit {
        width: $s1of6;
    }
    .grid--cols7upto480 > .grid__unit {
        width: $s1of7;
    }
    .grid--cols8upto480 > .grid__unit {
        width: $s1of8;
    }
}
@media (--min990) {
    .grid--cols1from990 > .grid__unit {
        width: $s1of1;
    }
    .grid--cols2from990 > .grid__unit {
        width: $s1of2;
    }
    .grid--cols3from990 > .grid__unit {
        width: $s1of3;
    }
    .grid--cols4from990 > .grid__unit {
        width: $s1of4;
    }
    .grid--cols5from990 > .grid__unit {
        width: $s1of5;
    }
    .grid--cols6from990 > .grid__unit {
        width: $s1of6;
    }
    .grid--cols7from990 > .grid__unit {
        width: $s1of7;
    }
    .grid--cols8from990 > .grid__unit {
        width: $s1of8;
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.bottom-sheet-v2__root {
    --bottom-sheet-max-width: auto;
    --bottom-sheet-margin-left: env(safe-area-inset-left);
    --bottom-sheet-margin-right: env(safe-area-inset-right);
    --bottom-sheet-background-color: var(--troika-milk);
}

.bottom-sheet-v2 {
    --border-radius: 16px;
    --y: 0px;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    display: flex;
    background: var(--bottom-sheet-background-color);
    flex-direction: column;
    transform: translate3d(0, var(--y), 0);
    will-change: --border-radius, --y, height;
    &:focus {
        outline: none;
    }
    &--non-blocking {
        box-shadow: hsla(200, 50%, 30%, 0.11) 0 -5px 60px 0px,
            hsla(200, 50%, 30%, 0.05) 0 -1px 0;
    }
    &,
    &__filler {
        max-width: var(--bottom-sheet-max-width);
        margin-left: var(--bottom-sheet-margin-left);
        margin-right: var(--bottom-sheet-margin-right);
    }
    &,
    &__backdrop,
    &__filler {
        z-index: 3;
        overscroll-behavior: none;
        touch-action: none;
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
    }
    &__backdrop {
        /* minus 60 to workaround iOS yank when showing the keyboard */
        top: -60px;
        bottom: -60px;
        background-color: rgba(71, 68, 69, 0.8);
        will-change: opacity;
    }
    &__filler {
        pointer-events: none;
        background: var(--bottom-sheet-background-color);
        height: 1px;
        transform-origin: bottom;
        will-change: transform;
    }
    &__header,
    &__footer {
        flex-shrink: 0;
        &-inner {
            padding: 16px;
        }
    }
    &__header {
        text-align: center;
        user-select: none;
        box-shadow: 0 1px 0 hsla(203, 18%, 22%, 0.125);
        z-index: 1;

        &-inner {
            padding-top: 8px;
            padding-top: calc(8px + env(safe-area-inset-top));
            padding-bottom: 8px;
            &:before {
                content: "";
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 8px;
                width: 25px;
                height: 4px;
                border-radius: 2px;
                background-color: env(--troika-sardine);
            }
        }
        &--empty {
            box-shadow: none;
        }
        &--empty &-inner {
            &:before {
                margin-bottom: 0;
            }
        }
    }
    &__content {
        flex-shrink: 1;
        flex-grow: 1;
        -webkit-tap-highlight-color: revert;
        -webkit-touch-callout: revert;
        user-select: auto;
        overflow: auto;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;
        &:focus {
            outline: none;
        }

        &--footer &-inner {
            padding-bottom: env(safe-area-inset-bottom);
        }
    }
    &__footer {
        box-shadow: 0 -1px 0 hsla(203, 18%, 22%, 0.125),
            0 2px 0 var(--bottom-sheet-background-color);
        overflow: hidden;
        z-index: 1;

        &-inner {
            padding-bottom: calc(16px + env(safe-area-inset-bottom));
        }
    }
    &__toolbar {
        display: flex;
        align-items: center;
        &-left,
        &-right {
            display: flex;
            width: 100%;
        }
        &-left {
            justify-content: flex-start;
        }
        &-center {
            flex-shrink: 0;
        }
        &-right {
            justify-content: flex-end;
        }
    }
}

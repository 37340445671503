@import "@finn-no/troika-css-dev/settings/s-fonts.css";

/*

    <div class="expandable expandable--open">
        <a class="expandable__clicktarget>Clicktarget</a>
        <div class="expandable__content">
            This is where the content is hanging
        </div
    </div>

*/

.expandable {
    &__clicktarget {
        display: inline-block;
        background: transparent; /* Removes a default bgcolor in Safari (?) */
        &::after {
            display: inline-block;
            content: " ";
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-licorice)
            );
            height: 18px;
            width: 18px;
            position: relative;
            left: 8px;
            transform: rotate(0deg);
            vertical-align: middle;
            .expandable--open & {
                transform: rotate(180deg);
            }
        }
        &:hover::after,
        &:focus::after {
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-secondary-blue)
            );
        }
    }

    &__content {
        display: none;

        .expandable--open & {
            display: block;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";
@import "@finn-no/troika-css-dev/tools/t-button.css";
@import "@finn-no/troika-css-dev/tools/t-link.css";
@import "@finn-no/troika-css-dev/tools/t-focus.css";

/* Map global -> local variables */
/*


        Generic button


        .button .button--cta
                .button--secondary
                .button--order

        .probably-not-touch class on body determines the button scale on non-touch environments
*/
$button-transition: $global-transition;
$button-radius: $global-radius;
$button-border-width: 2px;

a.button,
.button {
    display: inline-block; /* Reset Inline block (Element might not be button) */
    vertical-align: middle; /* Nicer when different sized buttons are next to each other */
    font: inherit;
    font-size: $base-size--normal;
    line-height: $base-lineheight--normal;
    font-weight: bold;
    text-align: center; /* Centered text on buttons */
    /* transition: $button-transition */
    cursor: pointer;
    margin: 0;
    min-width: 46px;
    border-radius: $button-radius;
    /* Default specific */
    color: env(--troika-primary-blue);
    background-color: env(--troika-milk);
    border: 2px solid env(--troika-sardine);
    padding: calc($button-padding-v - $button-border-width)
        calc($button-padding-h - $button-border-width);
    transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
    transition-property: background-color, border-color, color;
    @include focusable;

    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none; /* Kills link behaviour if its a A */
    }

    &:not(:active):not(:focus) {
        transition-duration: 100ms;
    }

    &:hover,
    &:active {
        background-color: env(--troika-ice);
        border-color: env(--troika-primary-blue);
    }
    &:active {
        background-color: #cfe1fd;
    }

    /* Default is the exception, so reset back to a common starpoint for the rest */
    &--cta,
    &--order,
    &--destructive,
    &--flat,
    &--link,
    &--is-disabled,
    &--destructive-flat,
    &--utility-flat,
    &--icon,
    &--on-image,
    &:disabled {
        &,
        &:hover,
        &:focus,
        &:active {
            border-color: transparent;
        }
    }

    &--cta {
        $primary-blue-focus: color-mod(
            env(--troika-primary-blue) lightness(-9.9%)
        );
        $primary-blue-active: color-mod(
            env(--troika-primary-blue) lightness(-19.7%)
        );
        @include buttonConstruct(
            env(--troika-milk),
            env(--troika-primary-blue),
            $primary-blue-focus,
            $primary-blue-active
        );
    }

    &--order {
        $pea-focus: color-mod(env(--troika-pea) lightness(-10.8%));
        $pea-active: color-mod(env(--troika-pea) lightness(-21.6%));
        @include buttonConstruct(
            env(--troika-licorice),
            env(--troika-pea),
            $pea-focus,
            $pea-active
        );
    }

    &--destructive {
        $cherry-focus: color-mod(env(--troika-cherry) lightness(-8.8%));
        $cherry-active: color-mod(env(--troika-cherry) lightness(-17.8%));
        @include buttonConstruct(
            env(--troika-milk),
            env(--troika-cherry),
            $cherry-focus,
            $cherry-active
        );
    }

    &--destructive-flat {
        color: env(--troika-cherry);
        background: transparent;

        &:hover,
        &:focus {
            background: #fbe9e6;
            color: color-mod(env(--troika-cherry) lightness(-8.8%));
        }

        &:active {
            color: color-mod(env(--troika-cherry) lightness(+10.9%));
        }
    }

    &--utility {
        border: 1px solid #c3ccd9;
        border-radius: 4px;
        padding: 11px 15px;
        @include buttonConstruct(
            env(--troika-licorice),
            env(--troika-milk),
            env(--troika-milk),
            #fafafa
        );
        &:hover {
            border-color: #737373;
        }
    }

    &--utility-flat {
        border-radius: 4px;
        @include buttonConstruct(
            env(--troika-licorice),
            env(--troika-milk),
            #f6f8fb,
            #f6f8fb
        );
    }

    &--flat {
        color: env(--troika-primary-blue);
        background: transparent;

        &:hover,
        &:focus {
            background: env(--troika-ice);
        }

        &:active {
            color: color-mod(env(--troika-primary-blue) lightness(+10.2%));
        }
    }

    &--link {
        @include link();

        &,
        &:focus,
        &:hover,
        &:active {
            background: transparent; /* safari defaults to grey */
            display: inline;
            padding: 0;
            font-weight: normal; /* revert button styling */
            text-align: left; /* revert button styling */
            box-shadow: none;
        }
    }

    &--pill {
        color: env(--troika-licorice);
        background: transparent;
        border: 0;
        padding: 4px;
        border-radius: 1.6em;
        min-height: 44px;
        min-width: 44px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &--left {
            justify-content: flex-start; /* Leftify content option */
        }

        &--left {
            justify-content: center; /* Center content option */
        }

        &:hover,
        &:focus {
            background: rgba(179, 209, 255, 0.3);
            background-clip: padding-box;
        }

        &:hover {
            border-color: rgba(255, 255, 255, 0.4);
        }

        &.button--small {
            padding: 4px;
            min-height: 36px;
            min-width: 36px;

            &::before {
                width: 18px;
                height: 18px;
            }
        }
    }

    &--icon {
        /* deprecated but still in use */
        &,
        &:link,
        &:visited {
            cursor: pointer;
            color: env(--troika-stone);
            text-decoration: none;
        }

        &:hover,
        &:focus,
        &:active {
            color: env(--troika-primary-blue);
            text-decoration: none;
            outline: none;
        }

        &,
        &:hover,
        &:focus,
        &:active {
            background: transparent; /* safari defaults to grey */
            display: inline;
            font-weight: normal; /* revert button styling */
            padding: 0;
        }
    }

    &:disabled,
    &--is-disabled {
        background: env(--troika-sardine);
        color: env(--troika-milk);
        background-image: none;
        cursor: default;
        pointer-events: none;
    }

    &--no-break {
        white-space: nowrap;
    }

    &--has-icon {
        /* needs support for button--small */
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        padding: 7px;

        &.button--default {
            padding: 5px;
        }

        & .button__img,
        &::before {
            flex: 0 0 auto;
        }

        &::before {
            /* Todo: add variants for alternative sizes ? */
            height: 32px;
            width: 32px;
        }

        &.button--icon-right .button__text {
            order: -1;
            padding: 0 4px 0 8px;
        }

        & .button__text {
            padding: 0 8px 0 4px;
        }
    }
}

.button {
    &--small&--small {
        padding: calc($button-padding-v-small - $button-border-width)
            calc($button-padding-h-small - $button-border-width);
        font-size: $d1-size--normal;
        line-height: $d1-lineheight--normal;
    }
    &--small&--utility {
        padding: 6px 15px;
        min-width: 30px;
    }
    &--small&--utility-flat {
        padding: 7px 16px;
        min-width: 30px;
        border: 0;
    }

    &--in-progress&--in-progress {
        pointer-events: none;
        border-color: transparent;
        color: env(--troika-stone);
        background-size: 30px 30px !important;
        background-color: #f1f4f6;
        background-image: linear-gradient(
            135deg,
            rgba(0, 0, 0, 0.05) 25%,
            transparent 25%,
            transparent 50%,
            rgba(0, 0, 0, 0.05) 50%,
            rgba(0, 0, 0, 0.05) 75%,
            transparent 75%,
            transparent
        ) !important;
        animation: animate-inprogress 3s linear infinite;

        @keyframes animate-inprogress {
            0% {
                background-position: 0 0;
            }

            100% {
                background-position: 60px 0;
            }
        }
    }
}

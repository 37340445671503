@import "@finn-no/troika-css-dev/settings/s-fonts.css";
/*


        Badge berry
      

*/

.badge {
    height: 18px;
    min-width: 18px;
    line-height: 19px;
    font-size: 11px;
    border-radius: 18px;
    padding-left: 3px;
    padding-right: 3px;
    background-color: env(--troika-cherry);
    color: env(--troika-milk);
    text-align: center;
    display: inline-block;

    &--top-right {
        position: absolute;
        top: 4px;
        right: 4px;
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.image-carousel {
    position: relative;

    &__button-left,
    &__button-right {
        position: absolute;
        top: 50%;
        top: calc(50% - 25px);
        background: rgba(0, 0, 0, 0.6);
        padding: 0;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        line-height: 0;
        border: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        touch-action: manipulation;
        outline: none;
        color: env(--troika-milk);
    }
    &__button-left {
        left: 16px;
    }
    &__button-right {
        right: 16px;
    }
    &__button-left,
    &__button-right {
        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }
        &:focus {
            box-shadow: 0 0 0 1px env(--troika-milk),
                0 0 0 4px var(--troika-secondary-blue);
        }
    }
    &__button-arrow {
        background: transparent;
        padding: 0;

        .image-carousel__button-right & {
            margin-left: 4px;
        }

        .image-carousel__button-left & {
            margin-right: 4px;
        }
    }
    &__caption {
        color: env(--troika-milk);
        margin: 0 16px 8px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
    }

    &__caption-text {
        padding: 4px 8px;
        background: rgba(
            0,
            0,
            0,
            0.6
        ); /* Strengthening to match the buttons better/also better contrast. */
        white-space: nowrap;
        border-radius: 4px;
    }
    &__video-swipe-layer {
        bottom: 50px;
        left: 45px;
        position: absolute;
        right: 45px;
        top: 45px;
        z-index: 1;
    }
}

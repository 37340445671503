@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-clearfix.css";

.step-indicator {
    width: 100%;
    display: block;
    position: relative;
    padding: 0;
    &__step {
        &::before {
            display: block;
            width: 26px;
            height: 26px;
            content: "";
            border: 4px solid #c3ccd9;
            border-radius: 50%;
            border-color: env(--troika-secondary-blue);
            background-color: env(--troika-secondary-blue);
            z-index: 1;
        }
        &::after {
            content: "";
            background-color: env(--troika-secondary-blue);
            z-index: 0;
        }
        &:last-child::after {
            content: none; /* Last item should not have a line */
        }
        &--is-current {
            &::before {
                background-color: white;
                border-color: env(--troika-secondary-blue);
            }
            &::after {
                background-color: env(--troika-sardine);
            }

            & ~ .step-indicator__step /* selects all that is after current. */ {
                &::before {
                    background-color: white;
                    border-color: env(--troika-sardine);
                }
                &::after {
                    background-color: env(--troika-sardine);
                }
            }
        }
    }

    &--horizontal {
        position: relative;
        display: flex;
        align-content: center;
        & .step-indicator__step {
            display: flex;
            align-items: center;
            flex: 1 0 auto;
            &::before {
                flex: 0 0 auto;
                width: 26px;
            }
            &::after {
                flex: 1 0 auto;
                height: 4px;
                width: 100%;
            }
            &:last-child {
                flex: 0 1 auto;
                width: 26px;
            }
        }
    }

    &--vertical {
        & .step-indicator__step {
            position: relative;
            min-height: 50px;
            margin-left: 26px;
            padding: 0px 16px 16px;
            &::before {
                position: absolute;
                left: -26px;
            }
            &::after {
                width: 4px;
                height: 100%;
                position: absolute;
                top: 25px;
                left: -15px;
                bottom: 0;
            }
        }
    }
}

/* Deprecated 18 feb. 2020 */
.step-indicator--deprecated {
    width: 100%;
    display: block;
    position: relative;
    @include clear-fix;
    & ul {
        z-index: 1;
        position: relative;
        padding: 0;
        & li {
            float: left;
            min-width: 40px;
            width: 20%; /* desides the width relative to parent */
            text-align: center;
            list-style-type: none;
            position: relative;

            &:before {
                width: 30px;
                height: 30px;
                content: "";
                position: relative;
                line-height: 20px;
                border: 5px solid #c3ccd9;
                display: block;
                text-align: center;
                margin: 0 auto 10px auto;
                border-radius: 50%;
                background-color: #fff;
                z-index: 2;
                /*content: counter(step);*/
                /*counter-increment: step;*/
            }
            &:after {
                width: 100%;
                height: 5px;
                content: "";
                position: absolute;
                background-color: env(--troika-sardine);
                top: 13px;
                left: -50%;
                z-index: 0;
            }

            &:first-child:after {
                content: none;
            }

            &.step-indicator--deprecated__step--is-complete {
                &:before {
                    border-color: env(--troika-secondary-blue);
                    background-color: env(--troika-secondary-blue);
                }

                & + li.step-indicator__step--is-complete:after,
                & + li.step-indicator__step--is-current:after {
                    background-color: env(--troika-secondary-blue);
                }
            }
            &.step-indicator--deprecated__step--is-current {
                &:before {
                    border-color: env(--troika-secondary-blue);
                    background-color: white;
                }
            }
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.ad-paging {
    display: flex;
    justify-content: space-between;
    &__prev,
    &__next,
    &__result {
        font-size: $base-size--normal;
        line-height: $base-lineheight--normal;
        flex: 0 0 auto;
        & span {
            vertical-align: middle;
        }
    }
    &__prev,
    &__next {
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            background-image: svg-load(
                "functions/arrow-up.svg",
                currentColor: env(--troika-primary-blue)
            );
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
        }
    }
    &__prev {
        padding-left: 21px;
        position: relative;
        &:before {
            left: 0;
            background-position: left;
            transform: rotate(-90deg);
        }
    }
    &__next {
        padding-right: 23px;
        position: relative;
        &:before {
            right: 0;
            background-position: left;
            transform: rotate(90deg);
        }
    }
    &__result {
        background-image: svg-load(
            "views/list-view.svg",
            currentColor: env(--troika-primary-blue)
        );
        background-repeat: no-repeat;
        background-position: left;
        padding-left: 26px;
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-data-table.css";

/*

    #TABLE

*/

.data-table {
    @include data-table;
    & td {
        border-top: 1px solid env(--troika-sardine);
    }
    &--all-left {
        & th,
        & td {
            text-align: left;
        }
    }
    &--all-right {
        & th,
        & td {
            text-align: right;
        }
    }
    &--truncate td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 1px;
    }
    &--borderless td {
        border-top: none;
    }
    &--blue {
        & thead th {
            background-color: env(--troika-toothpaste);
        }
        & th,
        & td {
            border: 0;
            &:first-child {
                padding-left: 16px;
                border-radius: 4px 0 0 4px;
            }
            &:last-child {
                padding-right: 16px;
                border-radius: 0 4px 4px 0;
            }
        }
        & tr:nth-of-type(even) {
            background-color: env(--troika-ice);
        }
    }
}

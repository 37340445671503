@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.step-dots {
    & ul {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        padding: 0;
        list-style: none;
        cursor: default;
        -webkit-touch-callout: none;
        user-select: none;
    }

    & li {
        position: relative;
        display: block;
        float: left;
        margin: 0 4px;
        width: 8px;
        height: 8px;
        cursor: pointer;
    }

    & li a {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        outline: none;
        border-radius: 50%;
        background-color: env(--troika-sardine);
        text-indent: -999em; /* make the text accessible to screen readers */
        cursor: pointer;
        position: absolute;
    }

    & li a:focus {
        outline: none;
    }

    &--scaleup {
        & li a {
            transition: transform 0.3s ease, background-color 0.3s ease;
        }

        & li a:hover,
        & li a:focus {
            background-color: env(--troika-primary-blue);
        }

        & li.current a {
            background-color: env(--troika-primary-blue);
            transform: scale(1.2);
        }
    }
}

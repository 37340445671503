@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";
@import "@finn-no/troika-css-dev/tools/t-panel.css";

/*

    #PANEL - PROJECT LOOP EDITION

*/

/* Map global to local variables */
$panel-radius: $global-radius;

/* panel layout configuration */
.panel-loop {
    @include panel;

    /* panel visual configuration */
    display: block;
    word-break: break-word;
    border-radius: $panel-radius;

    &--tips {
        background-color: env(--troika-ice);

        & .panel-loop__header {
            border-radius: $panel-radius $panel-radius 0 0;
            background-color: env(--troika-toothpaste);
            padding: 16px;
        }
    }
    &--success {
        background-color: env(--troika-ice);

        & .panel-loop__header {
            border-radius: $panel-radius $panel-radius 0 0;
            background-color: env(--troika-mint);
            padding: 16px;
        }
    }
}

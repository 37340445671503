@import "@finn-no/troika-css-dev/settings/s-fonts.css";
/*


        Skeleton screen loading indicator

        .skeleton-screen
            Will show a skeleton-screen like loading indicator as long as 
            the node with the class is :empty


*/

/* Map global -> local variables */
$skeleton-bg-color: env(--troika-ice);

.skeleton-screen:empty {
    background-color: $skeleton-bg-color;
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
    );
    background-repeat: repeat-y;
    background-size: 200px 100px;
    background-position: -30% 0;
    animation: shine 1.5s infinite;
}

@keyframes shine {
    to {
        background-position: 150% 0;
    }
}

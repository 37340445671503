.image-icon {
    height: 32px;
    width: 32px;
    background: rgba(0, 0, 0, 0.32);
    padding: 0;
    border-radius: 8px;
    border: 0;
    position: relative;
    text-align: center;
    vertical-align: top;
    cursor: pointer;
    &::before {
        display: block;
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        height: 22px;
        width: 22px;
        top: 5px;
        left: 5px;
        transition: scale 0.2s ease-out;
        transform: scale(1);
        transform-origin: center;
    }
    &:focus,
    &:hover {
        background: rgba(0, 0, 0, 0.64);
        outline: 0;
        &::before {
            transform: scale(1.3);
        }
    }

    &--favourite::before {
        background-image: svg-load("functions32/favourite-add-img.svg");
    }
    &--favourite-toggled::before {
        background-image: svg-load("functions32/favourite-added-img.svg");
    }
    &--more::before {
        background-image: svg-load("functions32/more-img.svg");
    }
    &--remove::before {
        background-image: svg-load("functions32/remove-img.svg");
    }
}

.form-grid {
    display: flex;
    flex-flow: row wrap;
    margin-left: -16px;
    margin-right: -16px;

    &--compact /* narrower forms */ {
        max-width: 700px;
        margin: 0 auto;
    }

    &__toggles /* For checkboxes and radios, these needs some sort of header, styled legend perhaps? */ {
        column-count: 2;
        display: block;
    }
    &__unit {
        flex: 1 1 0%;
        padding-left: 16px;
        padding-right: 16px;

        & &__button {
            margin-top: 23px;
        }

        &--g0 {
            flex: 0 1 auto;
        }
        &--g1 {
            flex: 1 1 0%; /* ie11 needs full shorthand including unit spec on the 3rd */
        }
        &--g2 {
            flex: 2 1 0%;
        }
        &--g3 {
            flex: 3 1 0%;
        }
        &--g4 {
            flex: 4 1 0%;
        }

        &--100 {
            &-lt768 {
                @media (--max768) {
                    min-width: 100%;
                    & .form-grid__unit__button {
                        margin-top: 0;
                    }
                }
            }
            &-lt990 {
                @media (--max990) {
                    min-width: 100%;
                    & .form-grid__unit__button {
                        margin-top: 0;
                    }
                }
            }
        }
        &--50-lt768 {
            @media (--max768) {
                min-width: 50%;
            }
        }
        &--50-lt990 {
            @media (--max990) {
                min-width: 50%;
            }
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-link.css";

.tabs {
    &__control {
        list-style: none;
        display: flex;
        font-size: $t4-size--normal;
        line-height: $t4-lineheight--normal;
        padding-left: 0; /* Reset default browser-padding */
        margin-bottom: -8px;
        &__tab {
            flex: 0 0 auto;
            padding: 16px 32px 24px 32px;
            border-radius: 8px;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                text-decoration: underline;
                outline: none;
            }

            &--is-active,
            &[data-selected] {
                background-color: env(--troika-ice);
                font-weight: bold;
            }
            a {
                @include link;
                @include link--block;
                @include link--dark;
                &:focus {
                    outline: none;
                }
            }
        }

        &--centered {
            align-items: center;
            justify-content: center;
        }

        &--justified &__tab {
            flex: 1 0 auto;
            text-align: center;
        }
    }

    &__content {
        margin-top: 0px;
        padding: 16px;
        border-radius: 8px;
        background: env(--troika-ice);
        display: none;
        outline: none;

        &__tab--is-active,
        &:not([hidden]) {
            display: block;
        }
    }
}

/* Let @reach/tabs know that we took care of styling it */
:root {
    --reach-tabs: 1;
}

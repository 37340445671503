@import "@finn-no/troika-css-dev/settings/s-fonts.css";

/*

        Decorated checkbox list 

        Thumbs used should be 100x100px

*/

.checkbox-list {
    &__input {
        padding: 0;
        margin: 0;
        float: left;
        position: absolute;
        left: 0;
        opacity: 0;
    }
    &__input:hover ~ &__label,
    &__input:focus ~ &__label {
        background-color: env(--troika-ice);
    }
    &__input:checked ~ &__label &__label__image::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.5);
        background-image: svg-load(
            "misc/checkmark-big.svg",
            currentColor: env(--troika-milk)
        );
        background-repeat: no-repeat;
        background-position: center center;
    }
    &__label {
        display: block;
        padding: 16px;
        border-radius: 16px;
        width: 132px;
        &__image {
            position: relative;
            & img {
                display: block;
                height: 100px;
                width: 100px;
            }
        }
    }
    &--in-progress &__label &__label__image {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 8px;
            background: rgba(0, 0, 0, 0.5);
            background-image: none !important;
        }
        &::after {
            content: "";
            display: block;
            border: 3px solid rgba(255, 255, 255, 0.4);
            border-top-color: env(--troika-milk);
            border-radius: 100%;
            position: absolute;
            height: 16px;
            width: 16px;
            top: 50%;
            right: 50%;
            margin-top: -8px;
            margin-right: -8px;
            animation: spinnerrotation 0.6s infinite linear;
        }
    }
}
/* Spinnercode to be moved out */
@keyframes spinnerrotation {
    to {
        transform: rotate(359deg);
    }
}

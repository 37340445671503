.flex-align {
    display: flex;
    align-items: center;

    &--space-between {
        justify-content: space-between;
    }
    &--space-around {
        justify-content: space-around;
    }
}

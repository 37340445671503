@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";
@import "@finn-no/troika-css-dev/tools/t-panel.css";
@import "@finn-no/troika-css-dev/tools/t-focus.css";

/*
    #PANEL
    Standardized themable grouping element
    Markup example:
    <div class="panel"></div>
    .panel.panel--banana.panel--is-clickable
*/

/* panel layout configuration */
.panel {
    @include panel;
    &--bleed {
        @include panel-bleed; /* slightly different behaviour if you have a colored background or a bleeding panel */
    }
    &--bordered,
    &--success,
    &--warning,
    &--error,
    &--info,
    &--tips,
    &--new,
    &--bright,
    &--neutral {
        @include panel-colored; /* inner spacing to content when you have a colored background */
    }
    &--kill-last-margin {
        *:last-child {
            margin-bottom: 0 !important;
        }
    }
}

/* panel visual configuration */
.panel {
    display: block;
    word-break: break-word;
    border-radius: $global-radius;
    border-color: var(--troika-sardine);
    &--is-clickable {
        @include focusable;

        &:hover {
            background-color: var(--troika-ice);
            cursor: pointer;
        }
    }
    &--is-roomy {
        @media (--min480) {
            padding: 64px;
        }
    }
    /* skins */
    &--bordered {
        border-width: 2px;
        border-style: solid;
    }
    &--success {
        background-color: var(--troika-mint);
        &:focus.panel--is-clickable,
        &:hover.panel--is-clickable {
            outline-color: color-mod(env(--troika-mint) lightness(-18%));
        }
    }
    &--warning {
        background-color: var(--troika-banana);
        &:focus.panel--is-clickable,
        &:hover.panel--is-clickable {
            outline-color: color-mod(env(--troika-banana) lightness(-18%));
        }
    }
    &--error {
        background-color: var(--troika-salmon);
        &:focus.panel--is-clickable,
        &:hover.panel--is-clickable {
            outline-color: color-mod(env(--troika-salmon) lightness(-19.5%));
        }
    }
    &--info {
        background-color: var(--troika-ice);
        &:focus.panel--is-clickable,
        &:hover.panel--is-clickable {
            outline-color: color-mod(env(--troika-ice) lightness(-19.5%));
        }
    }
    &--tips {
        background-color: var(--troika-toothpaste);
        &:focus.panel--is-clickable,
        &:hover.panel--is-clickable {
            outline-color: color-mod(env(--troika-toothpaste) lightness(-17%));
        }
    }
    &--bright {
        background-color: var(--troika-milk);
        &:focus.panel--is-clickable,
        &:hover.panel--is-clickable {
            outline-color: color-mod(env(--troika-milk) lightness(-20%));
        }
    }
    &--new {
        background-color: var(--troika-mint);
        border: 2px solid var(--troika-pea);
        &:focus.panel--is-clickable,
        &:hover.panel--is-clickable {
            outline-color: color-mod(env(--troika-pea) lightness(-10.9%));
        }
    }
    &--neutral {
        background-color: var(--troika-marble);
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";
@import "@finn-no/troika-css-dev/tools/t-panel.css";

/*

    <div className={`expansion-panel ${state}`.trim()}>
        <button href="#" class="expansion-panel__clicktarget">
            <div class="expansion-panel__summary">Whatever content</div>
        </button>
        <div className="expansion-panel__details">
            This is where the content is hanging
        </div>
    </div>

*/
/* Map global to local variables */

.expansion-panel {
    &:not(&--bleed) {
        @include panel;
    }
    &:not(&--bleed):not(&--bright) {
        background-color: env(--troika-ice);
        border-radius: $global-radius;
    }
    &--bright &__clicktarget {
        border-radius: 6px;
        background-color: env(--troika-milk);
        &:hover,
        &:active,
        &:focus {
            background-color: env(--troika-ice);
        }
        &:focus {
            box-shadow: hsl(207, 75%, 66%) 0 0 0 2px;
        }
    }
    &--bleed&--bright {
        margin: 0 8px;
        & + & {
            /* spacing for focus outline */
            margin-top: 2px;
        }
    }

    &__header {
        margin-bottom: 0;
    }

    &__clicktarget {
        @include panel-colored;
        background: transparent;
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        text-align: left;
        cursor: pointer;
        border: 0;
        outline: none;
        font-size: $base-size--normal;
        line-height: $base-lineheight--normal;

        &::after {
            display: block;
            margin-left: auto; /* Trick to rightify fixed width content using flex; */
            /*flex-shrink: 0;*/
            flex: 0 0 auto;
            content: " ";
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-licorice)
            );
            background-repeat: no-repeat;
            height: 18px;
            width: 18px;
            position: relative;
            align-self: center;
            justify-self: center;
        }

        &:hover::after,
        &:focus::after {
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-secondary-blue)
            );
        }
    }

    &--bright &__clicktarget {
        padding: 8px;
        font-size: 22px;
        font-weight: bold;
    }

    &--open &__clicktarget::after,
    &:not(&--open) &__clicktarget:active::after {
        transform: rotate(180deg);
    }
    &--open &__clicktarget:active::after {
        transform: rotate(0deg);
    }

    &--bright &__clicktarget {
        &::after,
        &:hover::after,
        &:focus::after {
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-licorice)
            );
            transform-origin: 50% 46%;
            height: 16px;
            width: 16px;
        }
    }
    &__summary {
        padding-right: 16px;
        width: 100%; /* Peventing width collapse in certain cases (grid) */
        color: env(--troika-licorice); /* Fixes bug in Safari */
    }

    &--bright &__summary {
        padding-right: 8px;
    }

    &__details {
        display: none;
        @include panel-colored;
        padding-top: 0;
    }

    &--bright &__details {
        padding: 8px;
    }

    &--open &__details {
        display: block;
    }
}

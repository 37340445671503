@import "@finn-no/troika-css-dev/settings/s-spacing.css";
@import "@finn-no/troika-css-dev/tools/t-pageholder.css";

.inline-container /* should use .u-display-inline.u-maa instead */ {
    display: inline-block;
    margin: auto !important;
}
.ad-info /* should not style stuff outside itself */ {
    position: absolute;
    height: 26px;
    width: 26px;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.45);
    border-bottom-right-radius: 13px;
    margin: 0;
}

.ad-info-wrap /* should not style stuff outside itself */ {
    position: absolute;
    display: block;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
}
.banners {
    @include pageholderNoPadding;
    padding-bottom: $global-outer-vertical-space--small;
    text-align: center;
    position: relative;
    background-color: transparent;

    &__topboard {
        height: 100%;

        &__cogwheel-button {
            @media (--min990) {
                position: absolute;
            }
        }
    }
    &__left-skyscraper {
        position: absolute;
        top: 0;
        left: -180px;
        @media (--max1300) {
            display: none;
        }
    }

    &__right-skyscraper {
        position: absolute;
        text-align: left;
        top: 0;
        right: 0px;
        @media (--max990) {
            display: none;
        }
    }

    &__sticky-skyscraper {
        position: fixed;
        transform: translateZ(0); /* Hardware accelerated scroll hack? */
        z-index: 2;
        width: 180px;
    }

    &__cogwheel-button {
        > .gdprAdTransparancyCogWheelButton   /* injected cogwheel element */ {
            background-color: rgba(255, 255, 255, 0.45);
            border-bottom-right-radius: 13px;
        }
    }

    &__is-horse-shoe {
        .banners__topboard__cogwheel-button {
            @media (--min1300) {
                display: none;
            }
        }
        .banners__skyscraperright_1__cogwheel-button {
            display: none;
        }
        .topboard-inline-container {
            .ad-info-wrap {
                @media (--min1300) {
                    display: none;
                }
            }
        }
        .skyscraperright_1-inline-container {
            .ad-info-wrap {
                display: none;
            }
        }
    }
}

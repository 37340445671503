.spinner {
    height: 15px;
    width: 15px;
    animation: rotation 0.6s infinite linear;
    border: 3px solid rgba(0, 0, 0, 0.15);
    border-radius: 100%;
    margin: auto;
    box-sizing: content-box;
}

/* Doing this instead of setting border-*-color on .spinner avoids some artifacts in some browsers. */
.spinner::before {
    content: "";
    display: block;
    position: absolute;
    left: -3px;
    top: -3px;
    height: 100%;
    width: 100%;
    border: 3px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.8);
    border-radius: 100%;
    box-sizing: content-box;
}

@keyframes rotation {
    to {
        transform: rotate(359deg);
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.slider {
    height: 34px;
    width: 100%;
    position: relative;
    user-select: none;
    touch-action: pan-y;

    &--is-disabled {
        pointer-events: none;
        opacity: 0.6;
    }

    &__track-inactive {
        position: absolute;
        height: 3px;
        bottom: 14px;
        width: 100%;
        background: env(--troika-sardine);
        border-radius: 4px;
    }
    &__track-active {
        position: absolute;
        height: 5px;
        bottom: 13px;
        background: env(--troika-primary-blue);
        border-radius: 4px;
    }
    &__thumb {
        background-color: env(--troika-primary-blue);
        border: 8px solid env(--troika-milk);
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1),
            0px 3px 1px 0px rgba(0, 0, 0, 0.05),
            0px 2px 2px 0px rgba(0, 0, 0, 0.05),
            0px 3px 3px 0px rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        height: 13px;
        width: 13px;
        position: absolute;
        bottom: 2px;
        box-sizing: content-box;
        cursor: pointer;
        transition: border-width 0.1s linear, padding 0.1s linear;
        -webkit-tap-highlight-color: transparent;

        &:focus,
        &:hover {
            outline: none;
            padding: 3px;
            border-width: 5px;
        }
    }
}

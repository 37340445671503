@import "@finn-no/troika-css-dev/settings/s-fonts.css";

$button-radius: 8px;

.segment-control {
    display: inline-flex;
    position: relative;
    border-radius: $button-radius;
    font-weight: bold;
    color: var(--troika-primary-blue);
    &:focus-within {
        box-shadow: 0 0 0 1px var(--troika-milk),
            0 0 0 3px var(--troika-secondary-blue);
    }
    &__item {
        flex: 0 0 auto;
        text-align: center; /* Centered text on buttons */
        cursor: pointer;
        background-color: var(--troika-milk);
        border-radius: 0;
        border: 2px solid var(--troika-sardine);
        padding: 10px 14px;
        transition-timing-function: cubic-bezier(0.46, 0.03, 0.52, 0.96);
        transition-property: background-color, border-color, color;
        &:not(:last-of-type) {
            border-right: 0;
        }

        &:first-of-type {
            border-top-left-radius: $button-radius;
            border-bottom-left-radius: $button-radius;
        }
        &:last-of-type {
            border-top-right-radius: $button-radius;
            border-bottom-right-radius: $button-radius;
        }
        &:hover,
        &:active,
        &:focus {
            background-color: var(--troika-ice);
        }
    }
    & [type="radio"] {
        opacity: 0;
        height: 1px;
        width: 1px;
        position: absolute;
        left: 0;
        text-decoration: none;
        &:disabled + .segment-control__item {
            color: #ddd;
            pointer-events: none;
        }
        &:hover {
            background-color: inherit;
            cursor: unset;
        }
    }
    & [type="radio"]:checked + &__item,
    &__item--checked&__item--checked {
        background: var(--troika-primary-blue);
        color: white;
        border-color: var(--troika-primary-blue);
        padding-right: 16px;
    }
    & [type="radio"]:checked + &__item + * + .segment-control__item,
    &__item--checked + .segment-control__item {
        border-left: 0;
    }
    & [type="radio"]:checked + &__item:hover,
    &__item--checked,
    &__item:hover,
    &__item:active,
    &__item:focus {
        text-decoration: none;
    }

    &--justified {
        display: flex;
        & .segment-control__item {
            flex: 1 0 auto;
        }
    }
    &--small {
        & .segment-control__item {
            padding: 5px 8px;
            font-size: 12px;
            line-height: 16px;
        }
    }

    & + .input__sub-text {
        display: block;
    }
}

.input {
    .segment-control {
        .segment-control__item {
            padding-bottom: 9px;
            color: var(--troika-primary-blue);
            font-size: 16px;
            line-height: 22px;
        }

        &--small {
            .segment-control__item {
                padding-bottom: 5px;
            }
        }
    }
}

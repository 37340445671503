@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    /* Bump specificity without using !important */
    &__page&__page {
        color: env(--troika-primary-blue);

        &[aria-current="page"] {
            color: env(--troika-milk);
            background: env(--troika-licorice);
        }
    }
}

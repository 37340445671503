@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";
/*


        Status ribbon

        .status .status--sold
                .status--lastcall


*/

$status-color-text: env(--troika-licorice);
$status-color-default: env(--troika-ice);
$status-color-success: env(--troika-mint);
$status-color-warning: env(--troika-banana);
$status-color-error: env(--troika-salmon);
$status-color-sponsored: env(--troika-toothpaste);
$status-color-disabled: env(--troika-sardine);
$status-radius: $global-radius;

.status {
    display: inline-block;
    color: $status-color-text;
    background-color: $status-color-default;
    font-size: $d1-size--normal;
    line-height: $d1-lineheight--normal;
    padding: 2px 8px;
    margin: 0 8px 16px 0;
    white-space: nowrap;
    border-radius: $status-radius;

    &:last-child {
        margin-right: 0;
    }
    &--success {
        background-color: $status-color-success;
    }
    &--warning,
    &--sold {
        background-color: $status-color-warning;
    }
    &--error {
        background-color: $status-color-error;
    }
    &--sponsored {
        background-color: $status-color-sponsored;
    }
    &--disabled,
    &--inactive {
        background-color: $status-color-disabled;
    }
}

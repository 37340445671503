@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-link.css";
@import "@finn-no/troika-css-dev/tools/t-focus.css";

/*
  BREADCRUMBS

    Usage:

    <nav aria-label="Her er du" class="breadcrumbs">
      <a href="#">FINN</a>
      <a href="#">Jobb</a>
      <a href="#">Heltidsstillinger</a>
      <span aria-current="page">Buzzword-konsulent</span>
  </nav>

  Supported because of wide usage in prod, but will be deprecated at some point:

    <ul class="breadcrumbs">
      <li><a href="#">FINN</a></li>
      <li><a href="#">Jobb</a></li>
      <li><a href="#">Heltidsstillinger</a></li>
      <li><a>Buzzword-konsulent</a></li>
  </ul>
*/

.breadcrumbs {
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    a {
        @include link;
        @include focusable;
    }
}

nav.breadcrumbs {
    a + a:before,
    a + span:before {
        color: env(--troika-licorice);
        content: "/";
        display: inline-block;
        margin: 0 8px;
    }

    /* Hide all breadcrumb nodes except the last 2 */
    @media (--max768) {
        a:not(:nth-last-child(-n + 2)) {
            display: none;
        }

        /* Turn off all seperators */
        a + a:before,
        a + span:before {
            display: none;
        }

        /* Turn on only the last seperator */
        a + *:last-child:before {
            display: inline-block;
        }
    }
}
/* Deprecated */
ol.breadcrumbs,
ul.breadcrumbs {
    li {
        display: inline;
    }

    li + li:before {
        content: "/";
        margin: 0 8px;
        padding: 16px 0;
    }

    /* Hide all breadcrumb nodes except the last 2 */
    @media (--max768) {
        li:not(:nth-last-child(-n + 2)) {
            display: none;
        }

        /* Turn off all seperators */
        li + li:before {
            display: none;
        }

        /* Turn on only the last seperator */
        li + li:last-child:before {
            display: inline-block;
        }
    }
}

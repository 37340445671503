@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-display.css";
@import "@finn-no/troika-css-dev/tools/t-shadow.css";
@import "@finn-no/troika-css-dev/tools/t-link.css";

.frontpage-search {
    position: relative;

    &__form {
        margin: 0;
        display: flex;

        &:focus-within {
            .frontpage-search__input {
                border-color: env(--troika-primary-blue);
                border-width: 2px;
                padding: 7px 35px 7px 7px;
            }
            ::-webkit-input-placeholder {
                color: env(--troika-sardine);
            }
        }
    }
    &__input {
        flex-grow: 1;
        border: 0;
        font-size: $t3-size--normal;
        padding: 8px 36px 8px 8px;
        color: env(--troika-licorice);
        border: 1px solid env(--troika-sardine);
        border-radius: 4px;
        -webkit-appearance: none;
        clear: both;

        &[type="search"]::-webkit-search-decoration,
        &[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            border-color: env(--troika-licorice);
        }

        @media (--max640) {
            font-size: $base-size--normal;
        }
    }
    &__submit {
        position: absolute;
        right: 9px;
        top: 10px;
        border: 0;
        -webkit-appearance: none;
        background-color: transparent;
        color: env(--troika-licorice);
        margin-bottom: 1px;
        border-radius: 0; /* recent weird new default in chrome gives certain elements rounded corners */
        cursor: pointer;
        padding: 0 0 3px;
        svg {
            width: 26px;
            height: 26px;
        }
        @media (--max640) {
            padding: 5px 5px 8px 5px;
            right: 4px;
            top: 4px;

            svg {
                width: 22px;
                height: 22px;
            }
        }

        &:disabled {
            color: env(--troika-sardine);
        }

        svg {
            vertical-align: middle;
        }

        &:focus-within,
        &:hover {
            color: env(--troika-secondary-blue);
        }

        &:focus {
            outline: 0;
            color: env(--troika-secondary-blue);
        }
    }
    &__status {
        @include screenReaderOnly;
    }

    &__container {
        background: env(--troika-milk);
        position: relative;
    }
    &--overlay {
        .frontpage-search__container {
            background: env(--troika-milk);
            padding: 0 16px;
            @include shadowSmall;
            border-radius: 0 0 8px 8px;
            overflow: hidden;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 2;
        }
    }
    &--long-text {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__result {
        margin: 16px 0;

        @media (--min640) {
            display: flex;
        }

        &__items {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            overflow: auto;
            width: 100%;
        }

        &__group-title {
            color: env(--troika-stone);
            font-size: $d1-size--normal;
            line-height: $d1-lineheight--normal;
            font-weight: normal;
            margin: 0;
            flex-shrink: 0;

            @media (--min640) {
                margin-right: 16px;
                width: 150px;
                font-size: $base-size--normal;
                line-height: $base-lineheight--normal;
                padding-top: 4px;
            }
        }
        &__link {
            @include link;
            @include link--dark;
            display: flex;
            align-self: center;
            width: 100%;
            padding: 4px 8px;
            &:focus {
                outline: none;
            }
        }
        &__selected {
            background-color: env(--troika-ice);
            text-decoration: underline;
            outline: none;
        }

        &__image {
            align-self: center;
            margin-right: 8px;
            max-width: 100px;
        }
        &__main-text {
            color: env(--troika-licorice);
            font-weight: bold;
        }
        &__text {
            font-size: $d1-size--normal;
            line-height: $d1-lineheight--normal;
            align-self: center;
            color: env(--troika-licorice);
            overflow: auto;

            @media (--min640) {
                font-size: $base-size--normal;
                line-height: $base-lineheight--normal;
            }
        }

        &__minor-text {
            color: env(--troika-licorice);
            @media (--min640) {
                font-size: $d1-size--normal;
                line-height: $d1-lineheight--normal;
            }
        }
        &__icon {
            display: block;
            align-self: center;
            margin-right: 8px;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.skiplink {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0
        /* This has opacity 0 instead of display: none to avoid layout when it's focused */;
    background: var(--troika-milk);
    color: var(--troika-licorice);
    padding: 5px 10px;
    z-index: -1;

    &:focus {
        opacity: 1;
        z-index: 500;
    }
    &--inline {
        position: absolute;
        left: -9999px;

        &:focus {
            position: relative;
            left: auto;
        }
    }
}

@mixin focus-outline {
    outline: 2px solid var(--troika-secondary-blue);
    outline-offset: 1px;
}

@mixin focusable {
    &:focus {
        @include focus-outline;
    }
    &:focus:not(:focus-visible) {
        outline: none;
    }
    &:focus-visible {
        @include focus-outline;
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
/*


        List styles


*/

.list {
    list-style: none;
    padding: 0;
    & li,
    &__item {
        line-height: 22px;
        position: relative;
        margin-bottom: 0;
    }
    &__item {
        &__title {
            font-weight: bold;
        }
        &__body {
            color: env(--troika-stone);
        }
    }
    &--compact li,
    &--compact &__item {
        font-size: 14px;
        line-height: 18px;
    }
    &--large li,
    &--large &__item {
        font-size: 22px;
        line-height: 28px;
    }
    &--large &__item__title {
        font-weight: normal;
    }
    &--large &__item__title + &__item__body {
        font-size: $caption-size--normal;
        line-height: $caption-lineheight--normal;
    }
    &--vertical li, /* -- deprecated */
    &--vertical &__item /* -- deprecated */ {
        position: relative;
    }
    &--horizontal li, /* -- deprecated */
    &--horizontal &__item /* -- deprecated */ {
        display: inline;
    }
    &--unordered, 
    &--bullets /* -- deprecated */ {
        list-style: disc outside none;
        padding-left: 32px;
        & li,
        & .list__item {
            padding: 0px;
            margin-left: 0;
            margin-bottom: 16px;
        }
    }
    &--ordered {
        list-style: none;
        counter-reset: item;
        padding: 0;
        & li,
        & .list__item {
            counter-increment: item;
            padding-left: 40px;
            margin-bottom: 16px;
        }
        & li::before,
        & .list__item::before {
            color: env(--troika-primary-blue);
            position: absolute;
            left: 16px;
            content: counter(item) ".";
        }
    }
    &--checked {
        list-style: none;
        padding: 0;
        & li,
        & .list__item {
            padding-left: 48px;
            position: relative;
            margin-bottom: 16px;
        }
        & li::before,
        & .list__item::before {
            background-image: svg-load(
                "24x24/check.svg",
                currentColor: env(--troika-primary-blue)
            );
            content: "";
            display: block;
            height: 24px;
            width: 24px;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            left: 14px;
            top: -1px;
        }
        &.list--large .list__item::before {
            top: 1px;
        }
    }

    @media (--min768) {
        &--cols1to2 /*-- Deprecated, use css-cols instead */ {
            column-count: 2;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.save-ad {
    &__modal {
        max-width: 512px;
    }

    &--render-num-favs {
        display: inline-flex;
        flex-direction: column;
    }

    .button &__icon-heart-active,
    .button &__icon-heart-neutral {
        height: 16px;
        width: 16px;
        margin-right: 8px;
        margin-left: 2px;
        margin-bottom: 2px;
    }
    &__icon-heart-active:before {
        background-image: svg-load(
            "24x24/heart-active.svg",
            currentColor: env(--troika-primary-blue)
        );
    }
    &__icon-heart-neutral:before {
        background-image: svg-load(
            "24x24/heart-neutral.svg",
            currentColor: env(--troika-primary-blue)
        );
    }

    &__login {
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
        padding: 16px 32px 32px 32px;
        &:before {
            display: block;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            height: 106px;
            width: 106px;
        }
    }

    /* @TODO combine the two folder-favorite-default.svg classes, one is a 'background-image' the other uses 'content' */
    &__login:before {
        /*, &__list-button__image--folder-icon:before */
        content: svg-load("other/folder-favorite-default.svg");
    }

    &__back-button {
        &:before {
            display: inline-block;
            height: 18px !important;
            width: 10px !important;
            content: "";
            margin-right: 8px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            background-image: svg-load(
                "functions/arrow-left.svg",
                currentColor: env(--troika-primary-blue)
            );
        }
        &:hover:before,
        &:focus:before {
            background-image: svg-load(
                "functions/arrow-left.svg",
                currentColor:
                    color-mod(env(--troika-primary-blue) lightness(-9.9%))
            );
        }
        &:active:before {
            background-image: svg-load(
                "functions/arrow-left.svg",
                currentColor:
                    color-mod(env(--troika-primary-blue) lightness(+10.25%))
            );
        }
    }

    &__grid {
        margin: 0 -8px;
    }

    &__list-button {
        appearance: none;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: stretch;
        padding: 6px;
        border-radius: 12px;
        border: 2px solid transparent;
        background-color: transparent;
        color: env(--troika-licorice);
        &:hover,
        &:active,
        &:focus {
            background-color: env(--troika-ice);
            & .save-ad__list-button__label:after {
                background-color: env(--troika-ice);
            }
        }
        &:focus {
            outline: none;
            border-color: env(--troika-focused);
        }

        &__label {
            display: block;
            margin-top: 4px;
            text-align: left;
            font-size: 14px;
            position: relative;
            line-height: 1.2em;
            height: 2.4em;
            overflow: hidden;
            padding-right: 1em;
            text-align: justify;
            hyphens: auto;
            word-break: break-word;
            &:before {
                content: "...";
                position: absolute;
                right: 0;
                bottom: 0;
                transform: translateY(-0.15em);
            }
            &:after {
                content: "";
                position: absolute;
                right: 0;
                width: 1em;
                height: 2.2em;
                margin-top: 0.2em;
                background: white;
            }
        }
        &__image {
            display: block;
            padding-top: 100%;
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            background-color: env(--troika-ice);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border: 1px solid env(--troika-sardine);
            .save-ad__list-button[aria-pressed="true"] &,
            .save-ad__list-button--in-progress & {
                border-color: #62666d;
            }
            &--folder-icon {
                background-image: svg-load("other/folder-favorite-default.svg");
            }
            &--folder-gift-icon {
                background-image: svg-load("other/folder-gift-default.svg");
            }
            &--add-icon {
                background-color: transparent;
                background-image: svg-load(
                    "16x16/plus-mini.svg",
                    currentColor: env(--troika-sardine)
                );
                background-size: 20%;
                border-style: dashed;
            }
            &:before,
            &:after {
                display: none;
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
            }
            &:before {
                background-image: svg-load(
                    "misc/checkmark-big.svg",
                    currentColor: env(--troika-milk)
                );
                background-repeat: no-repeat;
                background-position: center center;
                background-color: rgba(0, 0, 0, 0.5);
                .save-ad__list-button[aria-pressed="true"] &,
                .save-ad__list-button--in-progress & {
                    display: block;
                }
                .save-ad__list-button--in-progress & {
                    background-image: none;
                }
            }
            &:after {
                border: 3px solid rgba(255, 255, 255, 0.4);
                border-top-color: env(--troika-milk);
                border-radius: 100%;
                height: 16px;
                width: 16px;
                top: 50%;
                left: 50%;
                margin-top: -8px;
                margin-left: -8px;
                animation: save-ad-rotation 0.6s infinite linear;
                .save-ad__list-button--in-progress & {
                    display: block;
                }
            }
        }
    }

    &__modal__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__modal__footer {
        justify-content: space-between !important;
        align-items: center;
    }
}
@supports (-webkit-line-clamp: 2) {
    .save-ad__list-button__label {
        padding-right: 0;
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        &:before,
        &:after {
            display: none;
        }
    }
}

/* Spinnercode to be moved out, stolen from checkbox-list.css */
@keyframes save-ad-rotation {
    to {
        transform: rotate(359deg);
    }
}

@import "@finn-no/troika-css-dev/tools/t-clearfix.css";

.definition-list {
    @include clear-fix;
    & dd,
    & dt {
        display: inline-block;
        float: left;
        margin: 0 0 10px 0;
        padding: 0;
    }
    & dt {
        font-weight: bold;
        padding-right: 10px;
        clear: left;
        &::after {
            content: ": ";
        }
    }
    & dd + dd::before {
        content: ", ";
    }
    &--inline {
        & dd + dd::before {
            content: "";
            padding-right: 5px;
        }
    }
    &--liquid {
        & dd {
            display: block;
            width: auto;
            float: none;
            text-align: right;
        }
        & dt {
            width: auto;
        }

        & dd + dd::before {
            content: "";
        }
    }
    &--inline {
        & dd {
            padding-right: 5px;
        }
        & dd + dd::before {
            content: "";
        }
    }

    @media (--min768) {
        &--cols1to2 {
            column-count: 2;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

@mixin link {
    &,
    &:link,
    &:visited {
        cursor: pointer;
        color: var(--troika-primary-blue);
        text-decoration: none;
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--troika-primary-blue);
        text-decoration: underline;
        outline-color: currentColor;
    }
}
@mixin link--history {
    &:visited,
    &:visited:hover {
        color: var(--troika-stone);
    }
}

@mixin link--block {
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

@mixin link--dark {
    &,
    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
        color: var(--troika-licorice);
    }
}

@mixin link--light {
    &,
    &:link,
    &:visited {
        color: var(--troika-stone);
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--troika-licorice);
    }
}

@mixin link--bright {
    &,
    &:link,
    &:visited {
        color: var(--troika-milk);
    }
    &:hover,
    &:focus,
    &:active {
        color: var(--troika-milk);
    }
}

@mixin link--nav {
    a&,
    & a,
    a&:link,
    & a:link,
    a&:visited,
    & a:visited {
        cursor: pointer;
        color: var(--troika-licorice);
        text-decoration: none;
    }
    a&:hover,
    & a:hover,
    a&:active,
    & a:active,
    a&:focus,
    & a:focus {
        text-decoration: none;
        outline: none;
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-panel.css";

/* Map global to local variables */
$broadcast-radius: 8px;

.broadcast {
    /* Standard inner/outer spacing */
    @include panel;
    @include panel-colored;
    /* In case someone posts a url or something with no spaces */
    word-break: break-word;
    /* Rounded corners */
    border-radius: $broadcast-radius;
    /* Styling variants */
    background-color: env(--troika-banana);
    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    *:last-child {
        margin-bottom: 0 !important;
    }

    &__content {
        min-height: 32px;
        vertical-align: middle;
        flex: 1;
        padding: 7px;
    }

    &::before {
        content: " ";
        background-image: svg-load(
            "functions32/important.svg",
            currentColor: env(--troika-stone)
        );
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
        margin-top: 1px;
        align-self: flex-start;
    }
}

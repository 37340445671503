/*


    #MEDIA

    Typically positions 2 elements next to each other
    where one takes the 'rest' of the available space.

    - - - - - - - - - - - - - - - - - - - - - - - - - - -

    Markup example:

    <div class="media">
      <div class="media__img"> ... </div>
      <div class="media__body"> ... </div>
    </div>

    - - - - - - - - - - - - - - - - - - - - - - - - - - -

    .media.media--reverse     swaps body/img position
    .media.media--top         top aligns body/img
    .media.media--bottom      bottom aligns body/img

    - - - - - - - - - - - - - - - - - - - - - - - - - - -

*/

.media {
    display: table;
    width: 100%;

    &__img,
    &__body {
        display: table-cell;
        vertical-align: middle;
    }
    &__img {
        width: 1px;
        padding-right: 16px;
        > img {
            max-width: none;
        }
    }
    &__body {
        width: auto;
        & > :last-child {
            margin-bottom: 0;
        }
    }
    &--reverse {
        direction: rtl;
        > .media__img,
        > .media__body {
            direction: ltr;
        }
        > .media__img {
            padding-right: 0;
            padding-left: 16px;
        }
    }
}
.media--top {
    > .media__img,
    > .media__body {
        vertical-align: top;
    }
}

.media--bottom {
    > .media__img,
    > .media__body {
        vertical-align: bottom;
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-shadow.css";

.suggestion-dropdown {
    background: env(--troika-ice);
    padding: 0;
    margin-bottom: 16px;
    @include shadowSmall;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 2;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    li {
        padding: 8px;
    }

    &__item--selected {
        background-color: env(--troika-milk);
    }
}

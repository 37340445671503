@mixin buttonConstruct($color, $bg, $focus-bg, $active-bg) {
    /* @TODO color-mod functions should be avoided, as they're not compatible with */
    /* themeing with CSS custom properties and dark mode, all colors should be defined explicitly */
    $focus-bg: color-mod($bg lightness(-20%)) !default;
    $active-bg: color-mod($bg lightness(-40%)) !default;
    color: $color;
    background-color: $bg;
    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
    &:hover,
    &:focus {
        color: $color;
        background-color: $focus-bg;
    }
    &:active {
        color: $color;
        background-color: $active-bg;
    }
}

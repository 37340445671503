@mixin panel {
    margin: 16px 16px 32px 16px;
    @media (--min480) {
        margin: 16px;
    }
}

@mixin panel-bleed {
    margin: 16px 0 48px 0; /* content on white gets 64 visual vertical px of white, colored bg needs a little more to look balanced */
    @media (--max480) {
        border-radius: 0 !important;
    }
    @media (--min480) {
        margin: 16px;
    }
}

@mixin panel-colored {
    padding: 16px;
}

@import "@finn-no/troika-css-dev/tools/t-clearfix.css";
@import "@finn-no/troika-css-dev/tools/t-pageholder.css";

/*


      PAGEHOLDER
      - Basic horizontal positioning of the site inside banners.
      - Responsive behavior.
      - spacing between banner areas and content (panel)


*/
.pageholder {
    @include pageholder;
    @include clear-fix; /* Fix for the overflow of childrens margins (background-image banners bug) Issue #237 */
    &--liquid {
        max-width: none;
    }
    &--1400 {
        max-width: 1400px;
    }
}

@mixin shadowSmall {
    box-shadow: 0 3px 4px 0 rgba(71, 68, 69, 0.14),
        0 3px 3px -2px rgba(71, 68, 69, 0.2), 0 1px 8px 0 rgba(71, 68, 69, 0.12);
}

@mixin shadowMedium {
    box-shadow: 0 8px 10px 1px rgba(71, 68, 69, 0.14),
        0 3px 14px 2px rgba(71, 68, 69, 0.12),
        0 5px 5px -3px rgba(71, 68, 69, 0.2);
}

@mixin shadowLarge {
    box-shadow: 0 16px 24px 2px rgba(71, 68, 69, 0.14),
        0 6px 30px 5px rgba(71, 68, 69, 0.12),
        0 8px 10px -5px rgba(71, 68, 69, 0.2);
}

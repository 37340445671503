@import "@finn-no/troika-css-dev/settings/s-fonts.css";
/*


		Image format tools, cropping, centering in a div, etc



		<div class="img-format img-format--ratio16by9 img-format--ratio4by3-lt768">
			<img class="img-format__img" src="..." alt="...">
		</div>




*/

/* Ratio constants */

$ratio1by1: 100%;
$ratio4by3: 75%;
$ratio3by2: 66.6666%;
$ratio16by9: 56.25%;
$ratio2by1: 50%;
$ratio3by4: 133.3333%;

/* responsive image formats */
.img-format {
    overflow: hidden;
    position: relative;
    transform-style: preserve-3d;

    &--ratio1by1 {
        padding-top: $ratio1by1;
    }
    &--ratio4by3,
    &--ratio-normal {
        padding-top: $ratio4by3;
    }
    &--ratio3by2 {
        padding-top: $ratio3by2;
    }
    &--ratio16by9,
    &--ratio-widescreen {
        padding-top: $ratio16by9;
    }
    &--ratio2by1 {
        padding-top: $ratio2by1;
    }
    &--ratio3by4 {
        padding-top: $ratio3by4;
    }
    &--rounded {
        border-radius: 50%;
    }
    &__img {
        max-width: 100%;
    }
    &--centered &__img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.02); /* Scale removes some of the mysterious pixels between image and frame */
    }
    &--crop-vertical &__img {
        width: 100%;
        height: auto;
        max-height: none;
    }

    &--crop-horizontal &__img {
        height: 100%;
        width: auto;
        max-width: none;
    }
    &--logo {
        background-color: env(--troika-milk);
    }
    &--logo &__img {
        max-height: 65%;
        max-width: 65%;
    }
}
@media (--max768) {
    .img-format {
        &--ratio1by1-lt768 {
            padding-top: $ratio1by1;
        }
        &--ratio4by3-lt768 {
            padding-top: $ratio4by3;
        }
        &--ratio3by2-lt768 {
            padding-top: $ratio3by2;
        }
        &--ratio16by9-lt768 {
            padding-top: $ratio16by9;
        }
        &--ratio2by1-lt768 {
            padding-top: $ratio2by1;
        }
        &--ratio3by4-lt768 {
            padding-top: $ratio3by4;
        }
    }
}

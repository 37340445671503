@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-input.css";
@import "@finn-no/troika-css-dev/tools/t-clearfix.css";

$day-hover-color: env(--troika-toothpaste);
$stop-start-day-color: env(--troika-primary-blue);
$range-day-color: env(--troika-toothpaste);
/* $range: env(--troika-toothpaste) */

.SingleDatePicker,
.DateRangePickerInput {
    .DateInput {
        font-size: $base-size--normal;
        line-height: $base-lineheight--normal;
        color: env(--troika-stone);
        position: relative;
        margin: 0 0 32px 0;
        @include clear-fix; /* fix for collapsing margin (1 to 2 px border without jumping content below) */

        & label {
            @include input__label;
        }
        & input {
            @include input__field;

            &:hover {
                @include input__field--hover;
            }

            &:active,
            &:focus {
                @include input__field--focus;
            }
        }
    }
}
.CalendarDay {
    &__default {
        &:hover {
            background: $day-hover-color;
            border: 1px double env(--troika-primary-blue);
        }
    }
    &__selected {
        &,
        &:active,
        &:hover {
            background: $stop-start-day-color !important;
            color: env(--troika-milk);
            border: 1px solid color-mod($stop-start-day-color lightness(-9.9%));
        }
        &_span {
            background: $range-day-color;
            border: 1px solid color-mod($range-day-color lightness(-17%));
            color: env(--troika-stone);
            &:hover {
                background: $stop-start-day-color !important;
                border: 1px solid
                    color-mod($stop-start-day-color lightness(-9.9%));
            }
        }
    }
    &__hovered {
        &_span {
            background: $range-day-color;
        }
    }
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";

.filters-overlay {
    display: block;
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    min-height: calc(100vh - 50px);
    background-color: rgba(255, 255, 255, 0.97);
    transform: translate3d(0, 120%, 0);
    transition: transform 0.3s;

    &--document-body {
        overflow: hidden;
        position: relative;
        height: 100%;
    }
    &--static {
        bottom: auto;
        transform: none;
        transition: none;
        min-height: auto;
        border-bottom: 1px solid env(--troika-sardine);
    }

    &.filters-overlay--is-active {
        transform: translate3d(0, 0, 0);
    }
    &__header {
        position: absolute;
        height: 65px;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid env(--troika-sardine);
    }

    &__content {
        position: absolute;
        top: 65px;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
}
.filters-overlay-displace {
    height: 65px;
}

@mixin data-table {
    min-width: 100%;
    border-collapse: collapse;
    text-align: left;
    & th,
    & td {
        line-height: inherit;
        padding: 8px 16px;
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    & th {
        vertical-align: middle;
    }

    & td,
    & tbody th {
        vertical-align: top;
    }

    & thead th {
        font-weight: bold;
    }

    & th,
    & td {
        &.u-text-left {
            text-align: left;
        }
        &.u-text-right {
            text-align: right;
        }
    }
}

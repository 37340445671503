@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";

.modal {
    display: none;
    position: fixed;
    opacity: 0;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;

    @media (--min480) {
        padding: 10vh 1vh;
        transform: translate3d(0, -100000px, 0);
    }
    &:not(.modal--is-fullscreen) {
        background-color: rgba(0, 0, 0, 0.35);
    }
    &--document-body {
        overflow: hidden;
        position: relative;
        height: 100%;
    }
    &--is-active {
        display: flex;
        flex-direction: row;
        opacity: 1;
        transform: translate3d(0, 0, 0);
        overflow-y: auto;
    }
    &__container {
        background-color: env(--troika-milk);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
    &.modal--is-max640:not(.modal--is-fullscreen) > .modal__container {
        @media (--min480) {
            max-width: 640px;
        }
    }
    &:not(.modal--is-fullscreen) > .modal__container {
        @media (--min480) {
            position: relative;
            max-width: 300px;
            max-height: 80vh;
            margin: auto;
            border-radius: $global-radius;
        }
    }
    &__content {
        padding: 16px 32px 0 32px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (--min640) {
            .modal--is-roomy & {
                padding: 32px 64px 0 64px;
            }
        }
    }
    &__footer {
        display: flex;
        flex-shrink: 0;
        justify-content: flex-end;
        padding: 32px;

        @media (--min640) {
            .modal--is-roomy & {
                padding: 32px 64px;
            }
        }
    }
}

/* Let @reach/dialog know that we took care of styling it */
:root {
    --reach-dialog: 1;
}

@import "@finn-no/troika-css-dev/settings/s-fonts.css";
/*


*/

.lightbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.9);

    &__content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    &__body {
        flex: 1 0 auto;
        position: relative;
    }
    &__image-counter {
        width: 60%;
        padding-left: 40%;
        text-align: center;
        display: inline-block;
        color: env(--troika-milk);
    }

    &__buttons {
        width: 40%;
        text-align: right;
        display: inline-block;
    }
    &__image-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%; /* This is needed to center the image in IE 11, because we set max-width */
        height: 100%;
    }
    &__current-image {
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    &__caption {
        text-align: center;
        color: env(--troika-milk);
        margin: 8px;
    }
    &__thumbs-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1 0 0%;
        overflow-y: auto; /* Needed for Firefox */
    }
    &__thumbs {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        /* IE bug: https:/*github.com/philipwalton/flexbugs#2-column-flex-items-set-to-align-itemscenter-overflow-their-container */
        max-width: 100%;
        text-align: center;
        margin: 0 16px;
    }
    &__thumb-link {
        display: inline-block;
        line-height: 100%;
        padding: 2px 4px;
    }
    &__thumb {
        display: block;
    }
    &__toggle-button {
        display: inline-block;
        color: env(--troika-milk);
        background: transparent;
        border: 0;
        padding: 8px 16px;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
            color: env(--troika-milk);
        }
    }
    &__close-button {
        color: env(--troika-milk);
        background: transparent;
        border: 0;
        padding: 8px 16px;
        cursor: pointer;

        &:hover,
        &:active,
        &:focus {
            color: env(--troika-milk);
        }
    }
    @media (max-width: 640px) {
        &__image-counter {
            padding-left: 16px;
            width: 30%;
            text-align: left;
        }

        &__buttons {
            width: 70%;
        }
    }
}

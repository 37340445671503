@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/settings/s-forms.css";
@import "@finn-no/troika-css-dev/tools/t-button.css";

/*


        Button-group

 */

/* Map global -> local variables */
$button-transition: $global-transition;
$button-radius: $global-radius;

/* Ghost buttons */
$button-border-width: 2px;

/* @TODO all colors should be explicit instead of using color-mod */
$button-group-bg-focus: color-mod(env(--troika-primary-blue) lightness(-9.9%));
$button-group-bg-active: color-mod(
    env(--troika-primary-blue) lightness(-19.7%)
);

.button-group {
    &__item {
        display: inline-block; /* Reset Inline block (Element might not be button) */
        vertical-align: middle; /* Nicer when different sized buttons are next to each other */
        font: inherit;
        font-size: $base-size--normal;
        line-height: $base-lineheight--normal;
        font-weight: bold;
        text-align: center; /* Centered text on buttons */
        margin: 0;
        cursor: pointer;
        border-radius: 0;
        border: 2px solid env(--troika-secondary-blue);
        padding: calc($button-padding-v - $button-border-width)
            calc($button-padding-h - $button-border-width);
        @include buttonConstruct(
            env(--troika-primary-blue),
            env(--troika-milk)
        );

        &:not(:last-child) {
            border-right: 0;
        }
        &:first-child {
            border-top-left-radius: $button-radius;
            border-bottom-left-radius: $button-radius;
        }
        &:last-child {
            border-top-right-radius: $button-radius;
            border-bottom-right-radius: $button-radius;
        }

        .button-group--small & {
            padding: calc($button-padding-v-small - $button-border-width)
                calc($button-padding-h-small - $button-border-width) !important;
            font-size: $d1-size--normal;
            line-height: $d1-lineheight--normal;
        }

        &:focus,
        &:hover,
        &:active {
            outline: none; /* Kills the default browser focus-ring */
            text-decoration: none; /* Kills link behaviour if its a A */
            background-color: env(--troika-ice);
        }

        &--is-active {
            @include buttonConstruct(
                env(--troika-milk),
                env(--troika-primary-blue),
                $button-group-bg-focus,
                $button-group-bg-active
            );
        }

        &--is-disabled {
            background: #f1f4f6;
            color: env(--troika-stone);
            border-color: env(--troika-sardine);
            background-image: none;
            cursor: default;
            &:hover,
            &:active,
            &:focus {
                outline: none;
            }
        }
    }

    &--justified {
        display: flex;
        & .button-group__item {
            flex: 1;
        }
    }
}

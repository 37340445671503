@import "@finn-no/troika-css-dev/settings/s-fonts.css";
@import "@finn-no/troika-css-dev/tools/t-input.css";
/*

    #input

    <div className="input input--text">
        <label htmlFor="xyx">Text</label>
        <input id="xyx" type="text" />
    </div>

*/

.input {
    font-size: $base-size--normal;
    line-height: $base-lineheight--normal;
    color: env(--troika-stone);
    position: relative;
    margin: 0 0 32px 0;

    & label,
    .label {
        @include input__label;
        cursor: pointer;
    }
    & input {
        @include input__field;

        &:hover {
            @include input__field--hover;
        }
        &:active,
        &:focus,
        &[aria-expanded="true"] {
            @include input__field--focus;
        }
    }
    &--textarea {
        & textarea {
            @include input__field;
            resize: vertical;
            min-height: 42px;
            @media (--min480) {
                min-height: 45px; /* height on desktop */
            }

            &:hover {
                @include input__field--hover;
            }
            &:active,
            &:focus {
                @include input__field--focus;
            }
        }
    }
    &--select {
        &__wrap {
            position: relative;

            & select::-ms-expand {
                display: none;
            }
            &::before {
                content: " ";
                background-image: svg-load(
                    "16x16/chevron-down.svg",
                    currentColor: env(--troika-licorice)
                );
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 62.5%;
                display: block;
                color: env(--troika-primary-blue);
                position: absolute;
                right: 0;
                top: 2px;
                bottom: 0;
                width: 34px;
                height: 100%;
                pointer-events: none;
                cursor: pointer;
            }
            .input--is-open &::before {
                background-image: svg-load(
                    "16x16/chevron-down.svg",
                    currentColor: env(--troika-secondary-blue)
                );
                transform: rotate(180deg);
            }
            & select {
                appearance: none;
                @include input__field;
                padding-right: 1.6em;
                cursor: pointer;

                &:hover {
                    @include input__field--hover;
                }
                &:active,
                &:focus {
                    @include input__field--focus;
                }
            }
            & option {
                height: 20px; /* FF height fix */
            }
        }
    }
    &--file {
        position: relative;

        & ::-webkit-file-upload-button {
            cursor: pointer;
        }
        & input[type="file"] {
            padding: initial;
            border: initial;
            line-height: initial;
            box-shadow: none;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
    &--number {
        & input[type="number"] {
            -moz-appearance: textfield;
        }
        & input[type="number"]::-webkit-inner-spin-button,
        & input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    &--search {
        & input {
            padding-right: 40px !important;
            appearance: none;

            &[type="search"]::-webkit-search-decoration,
            &[type="search"]::-webkit-search-cancel-button {
                -webkit-appearance: none;
            }
        }
        &__button {
            border: none;
            cursor: pointer;
            background: transparent;
            background-image: svg-load(
                "functions/search.svg",
                currentColor: env(--troika-licorice)
            );
            background-repeat: no-repeat;
            background-position: center center;
            display: block;
            color: env(--troika-primary-blue);
            position: absolute;
            right: 0;
            top: 0;
            top: 24px;
            bottom: 0;
            width: 40px;
            height: 42px;

            &:hover,
            &:focus {
                outline: none;
            }
            &--submit {
                background-image: svg-load(
                    "functions/search.svg",
                    currentColor: env(--troika-licorice)
                );

                &:hover,
                &:focus {
                    background-image: svg-load(
                        "functions/search.svg",
                        currentColor: env(--troika-secondary-blue)
                    );
                }
            }
            &--reset {
                background-image: svg-load(
                    "functions/remove.svg",
                    currentColor: env(--troika-licorice)
                );
                &:hover,
                &:focus {
                    background-image: svg-load(
                        "functions/remove.svg",
                        currentColor: env(--troika-secondary-blue)
                    );
                }
            }
        }
    }
    &--is-invalid {
        & input,
        & select,
        & textarea {
            &,
            &:hover {
                @include input--invalid;
            }

            &:active,
            &:focus {
                @include input--invalid--focus;
            }
        }
        & label,
        & .label,
        & .input__sub-text {
            color: env(--troika-cherry);
        }
        & .input--select__wrap::before {
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-cherry)
            );
        }
    }
    &--is-disabled {
        & input,
        & select,
        & textarea {
            &,
            &:hover,
            &:active,
            &:focus {
                @include input__field--disabled;
            }
        }
        & .input--select__wrap::before {
            background-image: svg-load(
                "16x16/chevron-down.svg",
                currentColor: env(--troika-sardine)
            );
        }
    }
    &--is-read-only {
        input,
        textarea,
        select {
            @include input--is-read-only;
            &,
            &:hover,
            &:active,
            &:focus {
                color: env(--troika-licorice);
                padding-bottom: $input-padding-v;
            }
        }
        & .input--select__wrap::before {
            background: none;
        }
    }
    /* Styling disabled look submitting icon when field is empty/disabled */
    &--is-empty &--search__submit,
    &--is-disabled &--search__submit,
    &--search__submit:disabled {
        &,
        &:link,
        &:visited,
        &:hover,
        &:focus {
            background-image: svg-load(
                "functions/search.svg",
                currentColor: env(--troika-sardine)
            );
            cursor: none;
        }
    }
    &--kill-bottom-margin {
        margin-bottom: 0 !important;
    }
    /* same as above, but no important specifier */
    &--no-bottom-margin {
        margin-bottom: 0;
    }
    &__sub-text {
        display: inline-block;
        color: env(--troika-licorice);
        margin: 4px 0 0;
        font-size: $d1-size--normal;
        line-height: $d1-lineheight--normal;
    }
}

.blockquote {
    padding-left: 40px;

    &::before {
        content: "\201C";
        position: absolute;
        margin-left: -40px;
        font-size: 55px;
        line-height: 40px;
        font-family: serif;
        font-weight: 700;
        color: #dfe4e8;
    }
}
